<template>
   <div id="inis-stair">
       <div class="cube"></div>
       <div class="cube"></div>
       <div class="cube black level-01"></div>
       <div class="cube"></div>
       <div class="cube black level-02"></div>
       <div class="cube black level-04"></div>
       <div class="cube black level-03"></div>
       <div class="cube black level-05"></div>
       <div class="cube black level-06"></div>
   </div>
</template>

<script>
export default {
    name: 'escalierInis',
}
</script>
