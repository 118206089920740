import Vue from 'vue'
const utf8 = require('utf8')

// Fetch an asset in Google Cloud Storage
// Using Craft Asset ID ref
const getAssetById = async (assetId, transform = '') => {
    try {
        const { asset } = await Vue.prototype.$craft.getAssetById(assetId, transform)
        return asset
    } catch (e) {
        // console.log(e)
        return false
    }
}

const parseFirestoreData = data => {
    try {
        // Base64 Decode
        data.jsonBody = atob(data.jsonBody)
        // Utf8 Decode
        data.jsonBody = utf8.decode(data.jsonBody)
        // Json Parse
        data.jsonBody = JSON.parse(data.jsonBody)

        if (data.jsonHeader) {
            data.jsonHeader = JSON.parse(data.jsonHeader)
        }
    } catch (e) {
        // this.setErrorsReportMsg(e)
        // console.log(e)
    }

    return data
}

const formatMemberTitle = title => {
    if (title === 'null') {
        return ''
    }

    let mergedTitle = ''
    const titleArray = title.split(';')
    titleArray.forEach((title, index) => {
        if (index === titleArray.length - 1 && titleArray.length > 1) {
            mergedTitle += ` et ${title}`
        } else if (index === 0) {
            mergedTitle += title
        } else {
            mergedTitle += `, ${title}`
        }
    })
    return mergedTitle.charAt(0) + mergedTitle.slice(1)
}

export { getAssetById, parseFirestoreData, formatMemberTitle }
