<template>
    <div>
        <a
            v-if="globals.contact.socialNetworks.facebook"
            class="icon-social-link"
            :href="globals.contact.socialNetworks.facebook"
            alt="facebook"
            target="_blank"
        >
            <span class="fa-stack">
                <i class="fa-solid fa-circle fa-stack-2x"></i>
                <i class="fa-brands fa-facebook-f fa-stack-1x fa-inverse"></i>
            </span>
        </a>

        <a
            v-if="globals.contact.socialNetworks.twitter"
            class="icon-social-link"
            :href="globals.contact.socialNetworks.twitter"
            alt="twitter"
            target="_blank"
        >
            <span class="fa-stack">
                <i class="fa-solid fa-circle fa-stack-2x"></i>
                <i class="fa-brands fa-twitter fa-stack-1x fa-inverse"></i>
            </span>
        </a>

        <a
            v-if="globals.contact.socialNetworks.linkedin"
            class="icon-social-link"
            :href="globals.contact.socialNetworks.linkedin"
            alt="linkedin"
            target="_blank"
        >
            <span class="fa-stack">
                <i class="fa-solid fa-circle fa-stack-2x"></i>
                <i class="fa-brands fa-linkedin-in fa-stack-1x fa-inverse"></i>
            </span>
        </a>

        <a
            v-if="globals.contact.socialNetworks.tiktok"
            class="icon-social-link"
            :href="globals.contact.socialNetworks.tiktok"
            alt="tiktok"
            target="_blank"
        >
            <span class="fa-stack">
                <i class="fa-solid fa-circle fa-stack-2x"></i>
                <i class="fa-brands fa-tiktok fa-stack-1x fa-inverse"></i>
            </span>
        </a>

        <a
            v-if="globals.contact.socialNetworks.instagram"
            class="icon-social-link"
            :href="globals.contact.socialNetworks.instagram"
            alt="instagram"
            target="_blank"
        >
            <span class="fa-stack">
                <i class="fa-solid fa-circle fa-stack-2x"></i>
                <i class="fa-brands fa-instagram fa-stack-1x fa-inverse"></i>
            </span>
        </a>

        <a
            v-if="globals.contact.socialNetworks.vimeo"
            class="icon-social-link"
            :href="globals.contact.socialNetworks.vimeo"
            alt="vimeo"
            target="_blank"
        >
            <span class="fa-stack">
                <i class="fa-solid fa-circle fa-stack-2x"></i>
                <i class="fa-brands fa-vimeo-v fa-stack-1x fa-inverse"></i>
            </span>
        </a>
        <a
            v-if="globals.contact.socialNetworks.youtube"
            class="icon-social-link"
            :href="globals.contact.socialNetworks.youtube"
            alt="youtube"
            target="_blank"
        >
            <span class="fa-stack">
                <i class="fa-solid fa-circle fa-stack-2x"></i>
                <i class="fa-brands fa-youtube fa-stack-1x fa-inverse"></i>
            </span>
        </a>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'SocialsIcons',

    // On loaded...
    mounted() {},

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
