<template>
    <div>
        <svg
            v-if="name === 'user'"
            version="1.1"
            class="icon-inis icon-user"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 108"
            style="enable-background: new 0 0 100 108"
            xml:space="preserve"
        >
            <g id="Symbols">
                <g id="Links_x2F_Login" transform="translate(-3.000000, -8.000000)">
                    <g id="Group" transform="translate(0.000000, 5.000000)">
                        <path
                            d="M53.1,61.5c-16.8,0-30.5-13.1-30.5-29.2S36.2,3.1,53.1,3.1s30.5,13.1,30.5,29.2S69.9,61.5,53.1,61.5z M53.1,12.1
				            c-11.9,0-21.6,9.1-21.6,20.2s9.7,20.2,21.6,20.2c11.9,0,21.6-9.1,21.6-20.2S65,12.1,53.1,12.1z"
                        />
                        <path
                            d="M88.1,110.9H18c-4.2,0-8.3-1.7-11.1-4.8C4,103,2.6,98.8,3.2,94.7l2-15.4c1.3-9.6,9.8-16.9,20-16.9c0,0,0,0,0,0H27
				            c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5h-1.8c0,0,0,0,0,0c-5.6,0-10.4,3.9-11.1,9.1l-2,15.4c-0.2,1.5,0.3,3,1.4,4.1
				            c1.1,1.2,2.8,1.9,4.5,1.9h70.2c1.7,0,3.4-0.7,4.5-1.9c1.1-1.1,1.6-2.6,1.4-4.1l-2-15.4c-0.7-5.2-5.4-9.1-11.1-9.1h-1.8
				            c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5h1.8c10.1,0,18.7,7.3,20,16.9l2,15.4c0.5,4.1-0.8,8.3-3.7,11.4
				            C96.4,109.1,92.4,110.9,88.1,110.9z"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'users'"
            version="1.1"
            class="icon-inis icon-user"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 30 30"
            style="enable-background:new 0 0 30 30;"
            xml:space="preserve"
        >
            <g id="Path-3">
            </g>
            <g>
                <path d="M8,18.4H6H5.9c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.3-0.2-0.5l0.3-2.5v-0.1C5.8,14.4,6.4,14,7.2,14h0.3h0.1
                    c0.5-0.1,0.8-0.5,0.8-1s-0.4-1-1-1H7.2H7c-1.7,0.1-3.1,1.3-3.3,3l-0.3,2.5v0.2c0,0.7,0.2,1.3,0.7,1.8s1.2,0.9,2,0.9h2
                    c0.5,0,1-0.4,1-1S8.5,18.4,8,18.4z"/>
                <path d="M10.5,12.1c0.7,0.2,1.3-0.3,1.3-1l0,0c0-0.5-0.4-0.9-0.8-1C9.6,9.8,8.5,8.6,8.5,7.2c0-1.7,1.5-3,3.3-3
                    c1.5,0,2.8,0.9,3.1,2.2c0.1,0.5,0.5,0.8,1,0.8l0,0c0.7,0,1.2-0.6,1-1.3c-0.6-2.2-2.7-3.8-5.2-3.8c-2.9,0-5.3,2.3-5.3,5.1
                    C6.5,9.6,8.2,11.6,10.5,12.1z"/>
                <path d="M27.1,24.1l-0.4-2.7c-0.2-0.8-0.6-1.5-1.2-2c-0.7-0.5-1.5-0.8-2.7-0.8c0,0,0,0-0.1,0c-0.2,0-0.5,0.2-0.6,0.3
                    c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,1.2,0.3c0.3,0,0.7,0.2,0.9,0.4c0.2,0.2,0.4,0.5,0.5,0.8l0.3,2.5
                    c0,0.3-0.1,0.6-0.5,0.6h-12c-0.3,0-0.4-0.2-0.4-0.6l0.4-2.7c0.1-0.3,0.3-0.6,0.5-0.8c0.3-0.2,0.6-0.3,1.4-0.3
                    c0.2,0,0.5-0.2,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.6-0.3-1.1-0.3c0,0,0,0-0.1,0l0,0
                    c-0.8,0-1.6,0.4-2.2,0.9c-0.6,0.5-1,1.3-1.1,2.1L10,24.2c0,0.7,0.2,1.4,0.7,1.9c0.5,0.6,1.3,0.9,2,0.9h11.8
                    c0.7-0.1,1.3-0.4,1.8-0.9C27,25.7,27.2,24.9,27.1,24.1z"/>
                <path d="M13.3,13.9c0,1.4,0.6,2.6,1.6,3.6s2.3,1.5,3.8,1.5s2.8-0.5,3.8-1.5s1.6-2.2,1.6-3.6s-0.6-2.6-1.6-3.6S20,8.9,18.6,8.9
                    s-2.8,0.5-3.8,1.5C13.8,11.3,13.3,12.6,13.3,13.9z M16.3,11.8c0.6-0.6,1.4-0.9,2.3-0.9s1.7,0.3,2.3,0.9c0.6,0.6,1,1.3,1,2.1
                    c0,0.8-0.3,1.6-1,2.1c-0.6,0.6-1.4,0.9-2.3,0.9s-1.7-0.3-2.3-0.9c-0.6-0.6-1-1.3-1-2.1C15.3,13.1,15.7,12.4,16.3,11.8z"/>
            </g>
        </svg>




        <svg
            v-if="name === 'magnifier'"
            version="1.1"
            class="icon-inis icon-magnifier"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 110"
            style="enable-background: new 0 0 100 110"
            xml:space="preserve"
        >
            <g id="Symbols">
                <g id="Group" transform="translate(-4.000000, -4.000000)">
                    <g id="Combined-Shape" transform="translate(4.500000, 4.000000)">
                        <path
                            d="M46.6,0.2c26,0,47.1,21.4,47.1,47.8c0,13.1-5.2,24.9-13.5,33.6l17.7,18c2.3,2.3,2.3,6.1,0,8.4c-2.1,2.1-5.4,2.3-7.7,0.6
				            l-0.7-0.6L70.8,89c-7.1,4.3-15.3,6.8-24.2,6.8c-26,0-47.1-21.4-47.1-47.8S20.6,0.2,46.6,0.2z M46.6,12.2
				            c-19.5,0-35.3,16-35.3,35.8s15.8,35.8,35.3,35.8s35.3-16,35.3-35.8S66.1,12.2,46.6,12.2z"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'filter'"
            version="1.1"
            class="icon-inis icon-filter"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background: new 0 0 100 100"
            xml:space="preserve"
        >
            <g transform="translate(-1 -1)">
                <path
                    d="M25.1,24c-3.1,0-5.6-2.5-5.6-5.6V6.6C19.5,3.5,22,1,25.1,1s5.6,2.5,5.6,5.6v11.9C30.6,21.5,28.1,24,25.1,24z"
                />
                <path
                    d="M25.1,101c-3.1,0-5.6-2.5-5.6-5.6V59.9c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6v35.6C30.6,98.5,28.1,101,25.1,101z"
                />
                <path
                    d="M25.1,62.5C11.8,62.5,1,52,1,39.1s10.8-23.3,24.1-23.3c13.3,0,24.1,10.5,24.1,23.3S38.3,62.5,25.1,62.5z M25.1,26.9
		            c-7.1,0-13,5.5-13,12.2c0,6.7,5.8,12.2,13,12.2s13-5.5,13-12.2C38,32.4,32.2,26.9,25.1,26.9z"
                />
                <g transform="matrix(1 0 0 -1 11.333333 18)">
                    <path
                        d="M65.6-83c-3.1,0-5.6,2.5-5.6,5.6v11.9c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6v-11.9C71.1-80.5,68.7-83,65.6-83z"
                    />
                    <path
                        d="M65.6-29.7c-3.1,0-5.6,2.5-5.6,5.6v35.6c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6v-35.6
			            C71.1-27.2,68.7-29.7,65.6-29.7z"
                    />
                    <path
                        d="M65.6-68.2c-13.3,0-24.1,10.5-24.1,23.3c0,12.9,10.8,23.3,24.1,23.3S89.7-32,89.7-44.9C89.7-57.7,78.9-68.2,65.6-68.2z
			             M65.6-32.6c-7.2,0-13-5.5-13-12.2c0-6.7,5.8-12.2,13-12.2c7.1,0,13,5.5,13,12.2C78.6-38.1,72.7-32.6,65.6-32.6z"
                    />
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'chart'"
            version="1.1"
            class="icon-inis icon-chart"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 95"
            style="enable-background: new 0 0 100 95"
            xml:space="preserve"
        >
            <g id="Symbols">
                <g id="Icons_x2F_small-icons_x2F_Durée" transform="translate(-2.000000, -2.000000)">
                    <path
                        id="Shape"
                        d="M52,2.3C24.4,2.3,2,23.4,2,49.5c0,26.1,22.4,47.2,50,47.2c27.6,0,50-21.2,50-47.3C102,23.4,79.6,2.3,52,2.3z
			             M51.9,87.4c-14.3,0-27.5-7.2-34.6-18.9s-7.1-26.1,0-37.8s20.3-18.9,34.6-18.9v37.8l35.7-16.9c2.8,5.2,4.3,11,4.3,16.9
			            C91.9,70.5,74,87.4,51.9,87.4L51.9,87.4z"
                    />
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'cart'"
            version="1.1"
            class="icon-inis icon-cart"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 101 108"
            style="enable-background: new 0 0 101 108"
            xml:space="preserve"
        >
            <g id="Symbols">
                <g id="Group" transform="translate(-2.000000, -3.000000)">
                    <g id="noun-cart-1563393" transform="translate(2.900000, 3.500000)">
                        <path
                            id="Shape"
                            class="icon-color"
                            d="M14.6-0.3H4.1C2.5-0.3,1,0.5,0.2,1.9c-0.8,1.4-0.8,3.1,0,4.5C1,7.8,2.5,8.7,4.1,8.7h10.5
					        l14.9,40.9c-4.5,0.8-8.3,3.7-10.2,7.8c-1.9,4.1-1.5,8.9,0.9,12.8c2.4,3.8,6.6,6.2,11.2,6.4H86c1.6,0,3.1-0.9,3.9-2.3
					        c0.8-1.4,0.8-3.1,0-4.5c-0.8-1.4-2.3-2.3-3.9-2.3H31.4c-1.6,0-3.1-0.9-3.9-2.3c-0.8-1.4-0.8-3.1,0-4.5c0.8-1.4,2.3-2.3,3.9-2.3
					        h40c3.9,0,7.6-1.2,10.8-3.4c3.2-2.2,5.6-5.3,7-8.9l10.1-26.9c0.5-1.4,0.3-2.9-0.5-4.2c-0.8-1.2-2.2-1.9-3.7-1.9H26.9
					        c-0.3,0-0.6,0-0.9,0.1l-2.8-7.7c-0.6-1.7-1.8-3.3-3.3-4.3C18.3,0.2,16.5-0.3,14.6-0.3L14.6-0.3z M88.5,22.3l-7.8,20.8
					        c-0.7,1.9-2,3.5-3.6,4.6c-1.7,1.1-3.6,1.7-5.6,1.8H39.1l-9.9-27.1L88.5,22.3z"
                        />
                        <path
                            id="Shape_00000010273927016788287910000017893003466198007739_"
                            d="M22.7,95.8c0,3.1,1.2,6,3.4,8.2
					        c2.2,2.2,5.1,3.4,8.2,3.4c3.1,0,6-1.2,8.2-3.4c2.2-2.2,3.4-5.1,3.4-8.2c0-3.1-1.2-6-3.4-8.2c-2.2-2.2-5.1-3.4-8.2-3.4
					        c-3.1,0-6,1.2-8.2,3.4C23.9,89.8,22.7,92.7,22.7,95.8L22.7,95.8z M38.8,95.8L38.8,95.8c0,1.2-0.5,2.4-1.4,3.3
					        c-0.9,0.9-2,1.4-3.3,1.4c-1.2,0-2.4-0.5-3.3-1.4c-0.9-0.9-1.4-2-1.4-3.3c0-1.2,0.5-2.4,1.4-3.3c0.9-0.9,2-1.4,3.3-1.4
					        c1.2,0,2.4,0.5,3.3,1.4C38.3,93.4,38.8,94.6,38.8,95.8L38.8,95.8z"
                        />
                        <path
                            id="Shape_00000101804099299127224700000014674159275576375430_"
                            d="M72.7,84.3c-3.1,0-6,1.2-8.2,3.4
					        c-2.2,2.2-3.4,5.1-3.4,8.2c0,3.1,1.2,6,3.4,8.2c2.2,2.2,5.1,3.4,8.2,3.4c3.1,0,6-1.2,8.2-3.4c2.2-2.2,3.4-5.1,3.4-8.2
					        c0-3.1-1.2-6-3.4-8.2C78.7,85.5,75.7,84.3,72.7,84.3z M72.7,100.4c-1.2,0-2.4-0.5-3.3-1.4c-0.9-0.9-1.4-2-1.4-3.3
					        c0-1.2,0.5-2.4,1.4-3.3c0.9-0.9,2-1.4,3.3-1.4c1.2,0,2.4,0.5,3.3,1.4c0.9,0.9,1.4,2,1.4,3.3c0,1.2-0.5,2.4-1.4,3.3
					        C75.1,99.9,73.9,100.4,72.7,100.4z"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'calendar'"
            version="1.1"
            class="icon-inis icon-calendar"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 93"
            style="enable-background: new 0 0 100 93"
            xml:space="preserve"
        >
            <g id="Symbols">
                <g
                    id="Icons_x2F_small-icons_x2F_calendrier"
                    transform="translate(-1.000000, -2.000000)"
                >
                    <g id="Group-3" transform="translate(1.500000, 2.750000)">
                        <path
                            d="M86.3,91.9H12.7C5.4,91.9-0.5,86-0.5,78.7V22.8c0-7.3,5.9-13.2,13.2-13.2h73.5c7.3,0,13.2,5.9,13.2,13.2v55.9
				            C99.5,86,93.6,91.9,86.3,91.9z M12.7,18.4c-2.4,0-4.4,2-4.4,4.4v55.9c0,2.4,2,4.4,4.4,4.4h73.5c2.4,0,4.4-2,4.4-4.4V22.8
				            c0-2.4-2-4.4-4.4-4.4H12.7z"
                        />
                        <path
                            d="M26,25.8c-2.4,0-4.4-2-4.4-4.4V3.7c0-2.4,2-4.4,4.4-4.4s4.4,2,4.4,4.4v17.6C30.4,23.8,28.4,25.8,26,25.8z"
                        />
                        <path
                            d="M73,25.8c-2.4,0-4.4-2-4.4-4.4V3.7c0-2.4,2-4.4,4.4-4.4s4.4,2,4.4,4.4v17.6C77.4,23.8,75.5,25.8,73,25.8z"
                        />
                        <rect x="3.9" y="31.7" width="91.2" height="8.8" />
                        <g id="Group-2" transform="translate(3.250000, 7.500000)">
                            <g id="Group" transform="translate(0.000000, 3.000000)">
                                <rect
                                    id="Rectangle-Copy-3"
                                    x="15.4"
                                    y="55"
                                    class="st0"
                                    width="8.8"
                                    height="8.8"
                                />
                                <rect
                                    id="Rectangle-Copy-4"
                                    x="41.8"
                                    y="55"
                                    class="st0"
                                    width="8.8"
                                    height="8.8"
                                />
                                <rect
                                    id="Rectangle-Copy-5"
                                    x="68.3"
                                    y="55"
                                    class="st0"
                                    width="8.8"
                                    height="8.8"
                                />
                            </g>
                            <g id="Group-Copy">
                                <rect
                                    id="Rectangle-Copy-3_00000088099967011492788430000000949435778811643316_"
                                    x="15.4"
                                    y="40.3"
                                    class="st0"
                                    width="8.8"
                                    height="8.8"
                                />

                                <rect
                                    id="Rectangle-Copy-4_00000107575173065268978720000000539408888426343334_"
                                    x="41.8"
                                    y="40.3"
                                    class="st0"
                                    width="8.8"
                                    height="8.8"
                                />

                                <rect
                                    id="Rectangle-Copy-5_00000111149333822252191160000016258130179196895145_"
                                    x="68.3"
                                    y="40.3"
                                    class="st0"
                                    width="8.8"
                                    height="8.8"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'computer'"
            version="1.1"
            class="icon-inis icon-computer"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 28 20"
            style="enable-background: new 0 0 28 20"
            xml:space="preserve"
        >
            <g id="Nos-formations">
                <g
                    id="_x5B_LD_x5D_-Page-programme-1"
                    transform="translate(-1256.000000, -552.000000)"
                >
                    <g id="Group-9-Copy" transform="translate(1235.000000, 308.000000)">
                        <g id="Group-12" transform="translate(20.000000, 238.000000)">
                            <g
                                id="Icons_x2F_small-icons_x2F_lieu"
                                transform="translate(2.500000, 7.000000)"
                            >
                                <g id="akar-icons:laptop-device">
                                    <path
                                        id="Shape"
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                        "
                                        d="M2.5,2.5
                                        C2.5,1.1,3.6,0,5,0h15c1.4,0,2.5,1.1,2.5,2.5v11.2h-20V2.5z M0,17.5h25"
                                    />
                                </g>
                                <g id="uiw:setting" transform="translate(8.500000, 3.000000)">
                                    <rect
                                        id="ViewBox"
                                        x="0"
                                        y="0"
                                        style="fill: none"
                                        width="8"
                                        height="8"
                                    />
                                    <path
                                        id="Shape_00000123428228460024071120000009856106959484787884_"
                                        d="M4.4,0c0.1,0,0.2,0.1,0.3,0.2L5,1C5.1,1,5.2,1,5.2,1
                                        c0.1,0,0.2,0.1,0.3,0.1l0.7-0.3c0.1-0.1,0.2,0,0.3,0.1l0.6,0.6c0.1,0.1,0.1,0.2,0.1,0.3L6.9,2.5C6.9,2.6,7,2.7,7,2.7
                                        C7,2.8,7.1,2.9,7.1,3l0.7,0.3C7.9,3.4,8,3.5,8,3.6L7.9,4.5c0,0.1-0.1,0.2-0.2,0.2L7.1,4.9C7.1,5,7,5.1,7,5.2
                                        c0,0.1-0.1,0.2-0.1,0.3l0.3,0.8c0,0.1,0,0.2-0.1,0.3L6.5,7.1c-0.1,0.1-0.2,0.1-0.3,0L5.5,6.8C5.4,6.9,5.3,6.9,5.2,7L4.9,7.1
                                        L4.7,7.8C4.6,7.9,4.5,8,4.4,8L3.6,8C3.5,8,3.4,7.9,3.4,7.8L3.1,7C3,7,2.9,6.9,2.8,6.9c-0.1,0-0.2-0.1-0.2-0.1L1.8,7.1
                                        c-0.1,0-0.2,0-0.3-0.1L0.9,6.5C0.8,6.4,0.8,6.3,0.8,6.2l0.3-0.7c0-0.1-0.1-0.2-0.1-0.3C1,5.1,0.9,5,0.9,4.9L0.2,4.7
                                        C0.1,4.7,0,4.5,0,4.4l0-0.8c0-0.1,0.1-0.2,0.2-0.2l0.8-0.4C1,2.9,1,2.8,1,2.8c0-0.1,0.1-0.2,0.1-0.3L0.8,1.8
                                        c-0.1-0.1,0-0.2,0.1-0.3l0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.1l0.8,0.3c0.1-0.1,0.2-0.1,0.2-0.1C2.8,1,2.9,1,3.1,0.9l0.3-0.7
                                        C3.4,0.1,3.5,0,3.6,0H4.4z M4,2.8C3.3,2.8,2.8,3.3,2.8,4S3.3,5.2,4,5.2c0.7,0,1.2-0.5,1.2-1.2S4.7,2.8,4,2.8z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'laurel'"
            version="1.1"
            class="icon-inis icon-laurel"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 454"
            style="enable-background: new 0 0 500 454"
            xml:space="preserve"
        >
            <g>
                <path
                    d="M495.4,162.1c-3.3-2.8-7.8-3.7-11.9-2.4l-10.6,3.3c-6.1,1.9-12,4.3-17.7,7.1c13-37.3,11.6-78.1-3.8-114.5l-4.7-10.7h0
                c-1.7-4.1-5.5-7-10-7.6c-4.4-0.6-8.9,1.3-11.6,4.8l-7.2,9.2v0c-6,7.9-11.1,16.3-15.3,25.3c-10.1-26.2-27-49.2-48.9-66.8l-8.2-6.6v0
                c-3.3-2.6-7.7-3.4-11.7-2.1c-4,1.3-7.1,4.5-8.2,8.6l-2.9,10c-17,59,17.4,117.1,56.6,146l8.8,6.6h1.1v0c1.8,12.2,5,24.1,9.6,35.5
                l1.9,4.5c-19.1,23.2-31.8,51-37.1,80.6l-0.9,4.7c-23.2,9.1-43.7,24-59.6,43.3l-4.5,5.6c-14.4-0.4-28.7,1.4-42.4,5.6l-6.2,1.5
                l-6.2-1.9c-13.8-4.1-28.1-6-42.4-5.6l-4.5-5.2c-16-19.2-36.6-33.9-59.9-42.9l-0.6-5.2c-5.3-29.3-18-56.8-36.9-79.8l1.9-4.5h0
                c4.5-11.4,7.8-23.3,9.7-35.5h1l8.8-6.6c39.1-28.9,73.6-87,56.6-146l-3.3-10.5c-1.1-4.1-4.2-7.3-8.2-8.6c-4-1.3-8.4-0.5-11.8,2.1
                l-8.2,6.6c-21.8,17.6-38.7,40.7-48.9,66.8c-4.2-8.9-9.3-17.4-15.3-25.3l-7.2-9.2c-2.7-3.5-7.2-5.4-11.6-4.8
                c-4.4,0.6-8.2,3.4-10,7.6l-4.7,10.7C33,92,31.7,132.9,44.7,170.2c-5.7-2.8-11.6-5.1-17.7-7.1l-10.6-3.3h0
                c-4.1-1.3-8.6-0.4-11.9,2.4c-3.3,2.8-5,7.1-4.4,11.4l1.5,10.9c5.7,38.3,24.2,73.6,52.4,100.1c-7.6,0.4-15.1,1.1-22.6,2.3l-11.5,1.8
                h0c-4.4,0.7-8.1,3.6-9.7,7.7c-1.8,4.2-1.1,9,1.6,12.6l6.9,9.2h0c24.7,30.7,60.3,50.6,99.4,55.4c-9.7,6.1-18.8,13.1-27.3,20.8
                l-7.8,7.1v0c-3.3,3-4.7,7.5-3.8,11.9c0.9,4.3,4,7.9,8.2,9.4l9.8,3.5c13,4.5,26.7,6.8,40.5,6.7c29-0.7,57.3-9.5,81.7-25.3L208.2,436
                c-1.3,3.1-1.3,6.6,0,9.7c1.3,3.1,3.8,5.6,6.9,6.8c1.5,0.5,3.1,0.8,4.7,0.9c5.2,0,9.8-3.1,11.7-8l18.4-45.2l18.3,45.3
                c1.9,4.8,6.6,8,11.7,8c1.6-0.1,3.2-0.4,4.7-0.9c3.1-1.3,5.6-3.7,6.9-6.8c1.3-3.1,1.3-6.6,0-9.7l-11.4-28.3
                c24.4,15.8,52.7,24.5,81.7,25.3c13.8,0.1,27.5-2.2,40.5-6.7l9.8-3.5v0c4.2-1.5,7.3-5,8.2-9.4c0.9-4.3-0.5-8.9-3.8-11.9l-7.8-7.1
                c-8.5-7.8-17.7-14.7-27.4-20.8c39.1-4.8,74.8-24.7,99.5-55.4l6.9-9.2c2.8-3.6,3.4-8.4,1.6-12.6c-1.7-4.1-5.4-7-9.7-7.7l-11.5-1.8v0
                c-7.5-1.2-15-1.9-22.6-2.3c28.3-26.5,46.7-61.8,52.4-100.2l1.5-10.9C500.5,169.4,498.8,164.9,495.4,162.1L495.4,162.1z M154.4,35.8
                c6.9,39.3-14.1,79.6-41.8,103.8h0c-2.9-39.4,12.8-78,42.4-104.2L154.4,35.8z M68,75.5c20.7,34.2,25.4,75.8,12.6,113.7
                C59.6,155.1,55,113.3,68,75.5z M28.5,190.5c40.9,18,70.9,54.3,80.8,97.9c-43.1-14.1-72-57.3-80.8-98.1V190.5z M170.7,348.9
                c-42.1,5.9-93.3-3.9-125.2-37.9C86.6,306.2,137.1,314.7,170.7,348.9z M116.9,405.2c29.8-25.5,69-37.3,107.9-32.3
                C197.4,396.8,156.2,413.8,116.9,405.2z M275.3,372.9c40.3-7.7,80.4,10,108.4,32.3C343.9,413.8,302.7,396.8,275.3,372.9z M432,75.5
                c13.1,37.9,8.4,79.6-12.6,113.7C406.7,151.2,411.3,109.7,432,75.5L432,75.5z M345,35.7L345,35.7c29.3,26.3,44.7,64.6,41.8,103.8
                C359.1,115,338.4,74.7,345,35.4L345,35.7z M329.3,348.9c33.6-34.2,84.2-42.7,125.2-37.9C422.7,345,371.4,354.8,329.3,348.9z
                 M390.7,288.2L390.7,288.2c10-43.6,39.9-79.8,80.8-97.9C462.7,231.1,433.8,274.3,390.7,288.2L390.7,288.2z"
                />
            </g>
        </svg>

        <svg
            v-if="name === 'trophy'"
            version="1.1"
            class="icon-inis icon-trophy"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 26 25"
            style="enable-background: new 0 0 26 25"
            xml:space="preserve"
        >
            <g id="Productions_x2F_Nouvelles_x2F_Réseau">
                <g
                    id="_x5B_D_x5D_-Page-productions---details---1"
                    transform="translate(-802.000000, -937.000000)"
                >
                    <g id="noun-trophy-4676888" transform="translate(803.000000, 937.250000)">
                        <path
                            id="Shape"
                            style="stroke: #000000; stroke-width: 0.25"
                            d="M23.5,2.1c-0.3-0.3-0.7-0.5-1.1-0.5H20V0.8c0-0.2-0.1-0.4-0.2-0.6
                        C19.6,0.1,19.4,0,19.2,0H4.8C4.6,0,4.4,0.1,4.2,0.2C4.1,0.4,4,0.6,4,0.8v0.8H1.6c-0.4,0-0.8,0.2-1.1,0.5S0,2.8,0,3.2V7v0
                        c0,0.4,0.1,0.9,0.4,1.3c0.2,0.4,0.6,0.7,1,0.9l3.8,1.9c0.3,0.4,0.6,0.9,1,1.2c0.9,0.9,1.9,1.5,3.1,1.8l-0.7,4.3l-0.8,0
                        c-0.6,0-1.1,0.2-1.5,0.5c-0.4,0.4-0.7,0.8-0.8,1.4L4.8,23l0,0c0,0.2,0,0.5,0.2,0.7C5.1,23.9,5.3,24,5.6,24h12.8
                        c0.2,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7l-0.5-2.7l0,0c-0.1-0.5-0.4-1-0.8-1.4c-0.4-0.4-1-0.5-1.5-0.5h-0.8l-0.7-4.3l0,0
                        c1.2-0.3,2.3-1,3.1-1.8c0.4-0.4,0.7-0.8,1-1.2l3.8-1.9c0.4-0.2,0.7-0.5,1-0.9C23.9,7.9,24,7.5,24,7V3.2C24,2.8,23.8,2.4,23.5,2.1
                        L23.5,2.1z M2,7.7C1.9,7.7,1.8,7.5,1.7,7.4C1.6,7.3,1.6,7.1,1.6,7V3.2H4v4c0,0.5,0.1,1.1,0.2,1.6L2,7.7z M17,20.6l0.4,1.8
                        l-10.8,0l0.4-1.8l0,0c0-0.2,0.1-0.3,0.3-0.5C7.3,20.1,7.5,20,7.7,20h8.6c0.2,0,0.4,0.1,0.5,0.2C16.9,20.3,17,20.5,17,20.6
                        L17,20.6z M13.8,18.4l-3.7,0l0.7-4c0.8,0,1.6,0,2.4,0L13.8,18.4z M16.7,11.2L16.7,11.2c-1,1.1-2.5,1.6-4,1.6h-1.6
                        c-1.5,0-2.9-0.6-4-1.6c-1-1.1-1.6-2.5-1.6-4V1.6h12.8v5.6C18.4,8.7,17.8,10.1,16.7,11.2L16.7,11.2z M22.4,7L22.4,7
                        c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3l-2.1,1.1v0C19.9,8.3,20,7.8,20,7.2v-4h2.4L22.4,7z"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'sheet'"
            version="1.1"
            class="icon-inis icon-sheet"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 473"
            style="enable-background: new 0 0 500 473"
            xml:space="preserve"
        >
            <g id="Calendrier_x2F_Emploi_x2F_">
                <g
                    id="_x5B_D_x5D_-Offre-emploi-détaillée"
                    transform="translate(-807.000000, -307.000000)"
                >
                    <g id="Group-4" transform="translate(785.000000, 281.000000)">
                        <g id="noun-contract-1183267" transform="translate(23.000000, 26.500000)">
                            <path
                                id="Shape"
                                style="stroke: #111111; stroke-width: 0.5"
                                d="M365.2,371.9c-7.3,0-13.2,5.9-13.2,13.2v48.5
                            c0,3.2-1.3,6.3-3.6,8.5c-2.3,2.2-5.4,3.5-8.6,3.4H37.2c-6.5,0-11.8-5.3-11.9-11.9V138.9h81.1c8.4,0,16.4-3.3,22.4-9.3
                            c5.9-5.9,9.3-14,9.3-22.4V26.1h201.9c6.6,0,11.9,5.3,11.9,11.9v68.5c0,7.3,5.9,13.2,13.2,13.2c7.3,0,13.2-5.9,13.2-13.2V38
                            c0-10.1-4-19.8-11.2-27c-7.2-7.2-16.9-11.2-27-11.2H136.3c-10.7,0-21,4.2-28.6,11.9l-96.8,96.8C3.2,116-1,126.3-1,137v296.7
                            c0,10.1,4,19.8,11.2,27c7.2,7.2,16.9,11.2,27,11.2h302.7c10.1,0,19.9-4,27-11.2c7.2-7.2,11.2-16.9,11.2-27v-48.5
                            C378.2,377.9,372.4,372.1,365.2,371.9L365.2,371.9z M111.8,44.8v62.4c0,1.4-0.6,2.7-1.5,3.7c-1,1-2.3,1.5-3.7,1.5H43.8
                            L111.8,44.8z"
                            />
                            <path
                                id="Shape_00000144315136789767475420000007721083701850004632_"
                                style="stroke: #111111; stroke-width: 0.5"
                                d="
                            M461.3,125.3c-5.7-5.7-13.5-8.9-21.6-8.9c-8.1,0-15.9,3.2-21.6,8.9L223.3,320.1c-1.8,1.8-3,4.2-3.4,6.7l-9.7,64.7h0
                            c-0.5,3.5,0.5,7,2.8,9.7c2.3,2.7,5.7,4.2,9.2,4.2h1.8l64.3-10.2c2.5-0.4,4.9-1.6,6.7-3.4l195-194.7c5.7-5.7,8.9-13.5,8.9-21.6
                            c0-8.1-3.2-15.9-8.9-21.6L461.3,125.3z M473,180.1l-192,192l-44.4,6.8l6.8-44.4l192-192c1.2-1.2,2.8-1.8,4.5-1.8
                            c1.7,0,3.3,0.7,4.5,1.8l28.7,28.8c1.2,1.2,1.8,2.8,1.8,4.5C474.8,177.3,474.1,178.9,473,180.1L473,180.1z"
                            />
                            <path
                                id="Path"
                                style="stroke: #111111; stroke-width: 0.15"
                                d="M299.1,117.5c0-3.5-1.4-6.8-3.9-9.3c-2.5-2.5-5.8-3.9-9.3-3.9
                            h-86.2c-7.3,0-13.2,5.9-13.2,13.2c0,7.3,5.9,13.2,13.2,13.2h86.2c3.5,0,6.8-1.4,9.3-3.9C297.7,124.3,299.1,121,299.1,117.5z"
                            />
                            <path
                                id="Path_00000047743682323417370670000012957465207018618758_"
                                style="stroke: #111111; stroke-width: 0.15"
                                d="
                            M291.5,191.7c0-3.5-1.4-6.8-3.9-9.3c-2.5-2.5-5.8-3.9-9.3-3.9H88.4c-7.3,0-13.2,5.9-13.2,13.2c0,7.3,5.9,13.2,13.2,13.2h190
                            C285.6,204.8,291.5,199,291.5,191.7L291.5,191.7z"
                            />
                            <path
                                id="Path_00000079461473799381128430000008282494316953584558_"
                                style="stroke: #111111; stroke-width: 0.15"
                                d="
                            M213.3,265.9c0-3.5-1.4-6.8-3.9-9.3c-2.5-2.5-5.8-3.9-9.3-3.9H88.4c-7.3,0-13.2,5.9-13.2,13.2c0,7.3,5.9,13.2,13.2,13.2h111.7
                            c3.5,0,6.8-1.4,9.3-3.9S213.3,269.4,213.3,265.9z"
                            />
                            <path
                                id="Path_00000143604250675797969230000014277173472797388978_"
                                style="stroke: #111111; stroke-width: 0.15"
                                d="M88.4,327
                            c-7.3,0-13.2,5.9-13.2,13.2c0,7.3,5.9,13.2,13.2,13.2h74.6c7.3,0,13.2-5.9,13.2-13.2c0-7.3-5.9-13.2-13.2-13.2H88.4z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'clack'"
            version="1.1"
            class="icon-inis icon-clack"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 450"
            style="enable-background: new 0 0 500 450"
            xml:space="preserve"
        >
            <g id="Productions_x2F_Nouvelles_x2F_Réseau">
                <g id="_x5B_D_x5D_-Réseau-detaillé" transform="translate(-804.000000, -810.000000)">
                    <g id="bx:bx-movie-play" transform="translate(804.000000, 810.000000)">
                        <path
                            id="Shape"
                            d="M450-0.3H50c-27.6,0-50,22.4-50,50v350c0,27.6,22.4,50,50,50h400c27.6,0,50-22.4,50-50v-350
                            C500,22.1,477.6-0.3,450-0.3z M450,149.7C450,149.7,450,149.7,450,149.7h-11.6l-66.7-100H450L450,149.7z M188.4,149.7l-66.7-100
                            h64.9l66.7,100H188.4z M313.4,149.7l-66.7-100h64.9l66.7,100H313.4L313.4,149.7z M50,49.7h11.6l66.7,100H50V49.7z M50,399.7v-200
                            h400l0,200H50z"
                        />
                        <polygon id="Path" points="200,374.7 337.5,299.7 200,224.7 			" />
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="name === 'teacher'"
            version="1.1"
            class="icon-inis icon-teacher"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 640 512"
            style="enable-background:new 0 0 640 512;"
            xml:space="preserve"
        >
            <path d="M160,64c0-35.3,28.7-64,64-64h352c35.3,0,64,28.7,64,64v288c0,35.3-28.7,64-64,64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384
                v-32c0-17.7,14.3-32,32-32h64c17.7,0,32,14.3,32,32v32h64V64H224v49.1C205.2,102.2,183.3,96,160,96V64z M160,128c53,0,96,43,96,96
                s-43,96-96,96s-96-43-96-96S107,128,160,128z M133.3,352h53.3c73.7,0,133.4,59.7,133.4,133.3c0,14.7-11.9,26.7-26.7,26.7H26.7
                C11.9,512,0,500.1,0,485.3C0,411.7,59.7,352,133.3,352z"/>
        </svg>




    </div>
</template>

<script>
export default {
    name: 'IconInis',

    props: {
        name: {
            type: String,
            required: true,
        },
    },
}
</script>
