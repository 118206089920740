<template>
    <nav class="navbar-main" :class="bgTheme">
        <div class="container-fluid h-100 ps-0">
            <div class="row align-items-center h-100">
                <div class="col-auto">
                    <div class="hamburger-wrapper">
                        <div class="menu menu--close">
                            <div class="menu__icon">
                                <div class="menu__line menu__line--1"></div>
                                <div class="menu__line menu__line--2"></div>
                                <div class="menu__line menu__line--3"></div>
                            </div>
                        </div>
                        <span class="menu-text">{{ $t('Menu') }}</span>
                    </div>
                </div>

                <div class="col">
                    <router-link :to="{ name: 'HomePage-fr' }" class="logo-link">
                        <LogoInis />
                    </router-link>
                </div>

                <div class="col-auto ms-auto">
                    <div class="menu-navbar-wrapper">
                        <ul class="nav menu-navbar" role="navigation">
                            <li class="nav-item">

                                <!-- Search Component -->
                                <section class="search-nav" role="search" data-ss360="true">

                                    <input type="search" id="searchBox" class="d-none d-lg-block" :placeholder="$t('Recherche')">

                                    <!-- <button id="searchButton" class="search-button d-none d-lg-block"></button> -->

                                    <button class="search-button d-none d-lg-block"><IconInis name="magnifier" /></button>
                                    <button class="search-button-get" @click="getResult()"><IconInis name="magnifier" /></button>

                                    <button class="search-button-mobile d-lg-none" @click="searchBoxShow()"><IconInis name="magnifier" /></button>
                                </section>

                            </li>

                            <li class="nav-item dropdown">
                                <router-link class="nav-link" :to="{ name: 'Panier-fr' }">
                                    <IconInis name="cart" />
                                    <span class="menu-title ms-2">{{ $t('Mon panier') }}</span>
                                    <span class="nb-items" v-if="cart && cart.selection.length">{{ nbQtyCart }}</span>
                                </router-link>
                            </li>

                            <li class="nav-item dropdown">
                                <a class="nav-link" :href="globalsIsLoaded ? globals.salesforce.salesforceUrl : ''">
                                    <IconInis name="user" />
                                    <span class="menu-title ms-2">
                                        {{ $t('Mon Dossier') }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import IconInis from '@/components/svgs/IconInis'
import LogoInis from '@/components/svgs/LogoInis'

export default {
    name: 'NavBar',

    components: {
        IconInis,
        LogoInis,
    },

    // On loaded...
    mounted() {
        document.querySelector('.hamburger-wrapper').addEventListener('click', () => {
            document.querySelector('#app').classList.remove('sidebar-main-open')
            document.querySelector('#app').classList.toggle('menu-main-open')
            document.querySelector('.menu--close').classList.toggle('open')
        });

        document.querySelector('#searchBox').addEventListener('focusin', () => {
           document.querySelector('#searchBox').classList.add('on-focus');
        });

        document.querySelector('#searchBox').addEventListener('focusout', () => {
           setTimeout(function () {
               document.querySelector('#searchBox').value = '';
               document.querySelector('#searchBox').classList.remove('on-focus');
           }, 500)
        });
    },

    computed: {
        nbQtyCart() {
            const items = this.cart ? this.cart.selection : []
            let qty = 0
            for (let i = 0; i < items.length; i++) {
                qty += parseInt(items[i].numberSelected)
            }
            return qty
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals', 'cart', 'bgTheme']),
    },
    methods: {
        searchBoxShow(){
            window.SS360.showResults('Formations');
        },
        getResult(){
            var searchText = document.getElementById("searchBox").value;
            if(searchText !== ''){
                window.SS360.showResults(searchText);
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
