<template>
    <div>
        <aside id="sidebar-nav" class="sidebar sidebar-main">
            <div class="sidebar-close-wrapper">
                <div class="line-01"></div>
                <div class="line-02"></div>
            </div>

            <div class="sidebar-visible">
                <div class="sidebar-text-wrapper">
                    <i class="icon fa-solid fa-arrow-left"></i>
                    <span class="sidebar-text">{{$t('Découvrir nos formations')}}</span>
                </div>
            </div>

            <div class="sidebar-content-wrapper" data-content="intro" v-if="sidebarStep === 'intro'">
                <!-- <div class="sidebar-breadcrumb">[breadcrumb]</div> -->

                <div class="sidebar-content">
                    <header class="sidebar-content-header">
                        <p class="sidebar-subtitle">{{$t('Recherchez nos formations par')}}</p>
                    </header>

                    <div class="sidebar-content-body">
                        <ul class="sidebar-list" v-if="pageIsLoaded">
                            <li>
                                <a href="#" @click.prevent="selectFilter('creation', false)" class="sidebar-filter-link">
                                    <h4 class="sidebar-list-title">{{$t('Domaines de création')}}</h4>
                                    <p class="sidebar-list-item-text">
                                        {{$t('Cinéma, Télévision, Documentaire, Fiction ...')}}
                                    </p>
                                    <!-- <span v-for="(item, i) in listOfCategoriesCreation" :key="i" class="sidebar-list-item-text">
                                        {{ item.title }}
                                    </span> -->
                                </a>
                            </li>

                            <li>
                                <a href="#" @click.prevent="selectFilter('jobs', false)" class="sidebar-filter-link">
                                    <h4 class="sidebar-list-title">{{$t('Métiers')}}</h4>
                                    <p class="sidebar-list-item-text">
                                        {{$t('Scénarisation, Production, Réalisation ...')}}
                                    </p>
                                    <!-- <span v-for="(item, i) in listOfCategoriesJob" :key="i" class="sidebar-list-item-text">
                                        {{ item.title }}
                                    </span> -->
                                </a>
                            </li>

                            <li>
                                <a href="#" @click.prevent="selectFilter('organisationsProfessionnelles', true)" class="sidebar-filter-link">
                                    <h4 class="sidebar-list-title">{{$t('Organisations professionnelles')}}</h4>
                                    <p class="sidebar-list-item-text">
                                        {{$t('Formations réservées aux membres d’associations et de syndicats en audiovisuel')}}
                                    </p>


                                    <!-- <span v-for="(item, i) in listOfCategoriesPro" :key="i" class="sidebar-list-item-text">
                                        {{ item.title }}
                                    </span> -->
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-content-footer">
                        <div class="sidebar-nav" @click.prevent="closePanel" >
                            <router-link :to="{ name: 'Formations-fr'}" class="sidebar-nav-link link-arrow right white-on-green large">{{$t('Voir toutes les formations')}}</router-link>
                            <br>
                            <router-link :to="{ name: 'FormationSurMesure-fr'}" class="sidebar-nav-link link-arrow right white-on-green large">{{$t('Formations sur mesure')}}</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sidebar-content-wrapper" data-content="creation" v-if="sidebarStep === 'creation' && !results">

                <div class="sidebar-back-btn-wrapper">
                    <a href="#" v-if="selectedJob" class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="sidebarStep = userSelectedOrganisationsPro ? 'organisationsProfessionnelles' : 'jobs'">{{$t('Retour')}}</a>
                    <a href="#" v-else class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="sidebarStep = 'intro'">{{$t('Retour')}}</a>
                </div>

                <div class="sidebar-content-intro" v-if="isOrganisationsProfessionnelles">
                    <div class="intro-content">
                        <p>
                            {{$t("L’inis offre des formations adaptées aux caractéristiques et aux besoins des associations professionnelles et syndicats en audiovisuel du Québec.")}}
                            <br/><br/>
                            {{$t("Ces formations sont réservées aux membres de ces")}}
                            <a href="#" @click.prevent="sidebarStep = 'associationsSyndicats'">{{$t("associations et syndicats")}}</a>.
                        </p>
                    </div>
                </div>

                <div
                    class="sidebar-content"
                    :class="isOrganisationsProfessionnelles ? 'with-intro' : ''"
                >
                    <header v-if="selectedJob" class="sidebar-content-header">
                        <div class="sidebar-breadcrumb">{{ selectedJob }}</div>
                        <p class="sidebar-subtitle">{{$t('Choisir un domaine de création?')}}</p>
                    </header>
                    <header v-else class="sidebar-content-header">
                        <p class="sidebar-subtitle">{{$t('Quel domaine de création vous intéresse?')}}</p>
                    </header>

                    <div class="sidebar-content-body">
                        <ul class="sidebar-list" v-if="pageIsLoaded">
                            <li v-for="(item, i) in globals.categoriesCreation" :key="i">
                                <a :href="item.slug" class="sidebar-filter-link" @click.prevent="selectCreationType(item.title)">
                                    <h4 class="sidebar-list-title mb-0">{{ item.title }}</h4>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-content-footer">
                        <div class="sidebar-nav" @click.prevent="closePanel" >
                            <router-link v-if="selectedJob" :to="{ name: 'Formations-fr', query: { metiers: selectedJob } }" class="sidebar-nav-link link-arrow right white-on-green large">{{$t('Voir toutes les formations')}}</router-link>
                            <router-link v-else :to="{ name: 'Formations-fr' }" class="sidebar-nav-link link-arrow right white-on-green large">{{$t('Voir toutes les formations')}}</router-link>
                            <!-- <a href="#" class="sidebar-nav-link">Bâtir votre parcours personnalisé de formation</a> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="sidebar-content-wrapper" data-content="jobs" v-if="sidebarStep === 'jobs' && !results">
                <div class="sidebar-back-btn-wrapper">
                    <a href="#" v-if="selectedCreationType" class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="sidebarStep = 'creation'">{{$t('Retour')}}</a>
                    <a href="#" v-else class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="sidebarStep = 'intro'">{{$t('Retour')}}</a>
                </div>

                <div class="sidebar-content">
                    <header v-if="selectedCreationType" class="sidebar-content-header">
                        <div class="sidebar-breadcrumb">{{ selectedCreationType }}</div>
                        <p class="sidebar-subtitle">{{$t('Choisir une catégorie de métiers')}}</p>
                    </header>

                    <header v-else class="sidebar-content-header">
                        <p class="sidebar-subtitle">{{$t('Quel métier vous intéresse?')}}</p>
                    </header>

                    <div class="sidebar-content-body">

                        <ul class="sidebar-list" v-if="pageIsLoaded">
                            <li v-for="(item, i) in globals.categoriesJob.filter(c => !c.onlyOrganisationsPro)" :key="i">
                                <a :href="item.slug" class="sidebar-filter-link" @click.prevent="selectJob(item.title, false)">
                                    <h4 class="sidebar-list-title mb-0">{{ item.title }}</h4>
                                </a>
                            </li>
                            <li @click="closePanel">
                                <router-link
                                    :to="{ name: 'Formations-fr' }"
                                    class="sidebar-filter-link text-yellow"
                                >
                                    <h4 class="sidebar-list-title text-yellow mb-0">{{$t('Voir toutes les formations')}}</h4>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-content-footer">
                        <div class="sidebar-nav" @click="closePanel" >
                            <!-- <a href="#" class="sidebar-nav-link">Bâtir votre parcours personnalisé de formation</a> -->
                        </div>
                    </div>

                </div>
            </div>

            <div class="sidebar-content-wrapper" data-content="results" v-if="results && !forceShowAssociations">

                <div class="sidebar-back-btn-wrapper">
                    <a href="#" class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="backToSelections">{{$t('Retour')}}</a>
                    <div class="sidebar-breadcrumb">{{ selectedCreationType }} / {{ selectedJob }}</div>
                </div>

                <div class="sidebar-content-intro" v-if="isOrganisationsProfessionnelles">
                    <div class="intro-content">
                        <p>
                            {{$t("L’inis offre des formations adaptées aux caractéristiques et aux besoins des associations professionnelles et syndicats en audiovisuel du Québec.")}}
                            <br/><br/>
                            {{$t("Ces formations sont réservées aux membres de ces")}}
                            <a href="#" @click.prevent="forceShowAssociations = true">{{$t("associations et syndicats")}}</a>.
                        </p>
                    </div>
                </div>

                <div
                    class="sidebar-content"
                    :class="isOrganisationsProfessionnelles ? 'with-intro' : ''"
                >

                    <header v-if="results.length > 1" class="sidebar-content-header">
                        <h3 class="sidebar-subtitle-big">{{$t('L\'inis vous propose')}}<br/><span class="result-quantity">{{results.length + '&nbsp;' + $t('formations')}}</span></h3>
                    </header>
                    <header v-else-if="results.length == 1" class="sidebar-content-header">
                        <h3 class="sidebar-subtitle-big">{{$t('L\'inis vous propose')}}<br/><span class="result-quantity">{{results.length + '&nbsp;' + $t('formation')}}</span></h3>
                    </header>
                    <header v-if="!results.length" class="sidebar-content-header">
                        <h3 class="sidebar-subtitle-big">{{$t('Aucune formation trouvé')}}</h3>
                    </header>

                    <div class="sidebar-content-body">

                        <div class="sidebar-content result">
                            <div class="sep-hor-white-thick mb-4"></div>
                            <p class="sidebar-subtitle-result" v-if="results.length > 3">{{$t('Des formations telles que')}}</p>

                            <ul class="sidebar-list" v-if="results">
                                <li v-for="(item, i) in results" :key="i">
                                    <p v-if="i < 3" class="sidebar-list-item">
                                        <span class="sidebar-list-item-text">
                                            {{ item.title }}
                                        </span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="sidebar-content-footer" v-if="results && results[0]">
                        <div class="sidebar-nav" >
                            <a :href="$router.resolve({ name: 'Formations-fr', query: { filters: selectedCreationType, metiers: selectedJob } }).href" class="button fill" v-if="results.length > 1 && !isOrganisationsProfessionnelles">Voir les {{ results.length }} formations</a>
                            <a :href="$router.resolve({ name: 'Formations-fr', query: { filters: selectedCreationType, metiers: selectedJob, sections: 'pour-professionnel-le-de-laudiovisuel' } }).href" class="button fill" v-if="results.length > 1 && isOrganisationsProfessionnelles">Voir les {{ results.length }} formations</a>
                            <a :href="$router.resolve({ name: 'Formation-fr', params: { slug: results[0].slug } }).href" class="button fill" v-else-if="results.length == 1">Voir la formation</a>
                            <a href="#" class="sidebar-nav-link link-arrow right white-on-green mt-4 mt-md-0" @click.prevent.stop="reset()">{{$t('Recommencer')}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sidebar-content-wrapper" data-content="organisationsProfessionnelles" v-if="sidebarStep === 'organisationsProfessionnelles' && !results">

                <div class="sidebar-back-btn-wrapper">
                    <!-- <div class="sidebar-breadcrumb">[breadcrumb]</div> -->
                    <a href="#" class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="sidebarStep = 'intro'">{{$t('Retour')}}</a>
                </div>

                <div class="sidebar-content-intro">
                    <div class="intro-content">
                        <p>
                            {{$t("L’inis offre des formations adaptées aux caractéristiques et aux besoins des associations professionnelles et syndicats en audiovisuel du Québec.")}}
                            <br/><br/>
                            {{$t("Ces formations sont réservées aux membres de ces")}}
                            <a href="#" @click.prevent="sidebarStep = 'associationsSyndicats'">{{$t("associations et syndicats")}}</a>.
                        </p>
                    </div>
                </div>

                <div class="sidebar-content with-intro">

                    <header class="sidebar-content-header">
                        <p class="sidebar-subtitle">{{$t('Recherchez ces formations par catégorie')}}</p>
                    </header>

                    <div class="sidebar-content-body">
                        <ul class="sidebar-list" v-if="pageIsLoaded">
                            <li v-for="(item, i) in globals.categoriesJob" :key="i">
                                <a :href="item.slug" class="sidebar-filter-link" @click.prevent="selectJob(item.title, true)">
                                    <h4 class="sidebar-list-title mb-0">{{ item.title }}</h4>
                                </a>
                            </li>
                            <li @click="closePanel">
                                <router-link
                                    :to="{ name: 'Formations-fr', query: { sections: 'pour-professionnel-le-de-laudiovisuel' } }"
                                    class="sidebar-filter-link text-yellow"
                                >
                                    <h4 class="sidebar-list-title text-yellow mb-0">{{$t('Voir toutes les formations')}}</h4>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-content-footer">
                        <div class="sidebar-nav" @click.prevent="closePanel" >
                            <!-- <a href="#" class="sidebar-nav-link">Recommencer</a> -->
                        </div>
                    </div>

                </div>


            </div>

            <div class="sidebar-content-wrapper" data-content="associationsSyndicats" v-if="sidebarStep === 'associationsSyndicats' && !results || forceShowAssociations">
                <div class="sidebar-back-btn-wrapper" v-if="forceShowAssociations">
                    <a href="#" class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="forceShowAssociations = false">{{$t('Retour')}}</a>
                </div>
                <div class="sidebar-back-btn-wrapper" v-else-if="selectedJob">
                    <a href="#" class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="sidebarStep = 'creation'">{{$t('Retour')}}</a>
                </div>
                <div class="sidebar-back-btn-wrapper" v-else>
                    <a href="#" class="sidebar-back-btn link-arrow left white-on-green" @click.prevent="sidebarStep = 'organisationsProfessionnelles'">{{$t('Retour')}}</a>
                </div>

                <div class="sidebar-content">
                    <div class="sidebar-content-header">
                        <div
                            class="association-wrapper"
                            v-for="(asso, i) in globals.associationsSyndicats.associationslist.associations"
                            :key="'association-' + i"
                            >

                            <h5 class="association-group-title">{{asso.groupTitle}}</h5>

                            <div class="associations-names-wrapper">
                                <p
                                    class="association-name"
                                    v-for="(assoName, i) in asso.allAssociations"
                                    :key="'association-name' + i"
                                    >
                                    {{assoName.singleAssociation.associationName}}

                                </p>
                            </div>

                        </div>
                    </div>

                    <div class="sidebar-content-footer">

                    </div>

                </div>
            </div>

        </aside>
        <div id="sidebar-curtain" @click="closePanel"></div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getAllDocs } from '@/firebase/init'

export default {
    name: 'SidebarMain',

    data() {
        return {
            // Firebase Firestore
            firestoreContent: [],
            firestoreContentLoaded: false,
            firestoreErr: null,

            sidebarStep: 'intro',
            selectedCreationType: null,
            selectedJob: null,
            isOrganisationsProfessionnelles: false,
            lastSelection: null,
            userSelectedOrganisationsPro: false,

            forceShowAssociations: false,
        }
    },

    watch: {
        pageIsLoaded: {
            async handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data

                }
            },
            immediate: true,
        },
        sidebarMainStep: {
            async handler(sidebarMainStep) {
                if (sidebarMainStep) {
                    // Page loaded with Data
                    this.sidebarStep = sidebarMainStep
                    this.togglePanel()
                }
            },
            immediate: true,
        },
    },

    // On loaded...
    mounted() {
        document.querySelector('.sidebar-visible').addEventListener('click', () => {
            this.togglePanel()
        });

        setTimeout(function () {
            // define a function that sets min-height of my-element to window.innerHeight:
            const setHeight = () => {
                document.getElementById("sidebar-nav").style.minHeight = window.innerHeight + "px"
            };

            // define mobile screen size:
            let deviceWidth = window.matchMedia("(max-width: 991px)");
            if (deviceWidth.matches) {
                // set an event listener that detects when innerHeight changes:
                window.addEventListener("resize", setHeight);
                setHeight();
            }
        }, 2000)

    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        listOfCategoriesCreation() {
            return this.globals.categoriesCreation.slice(0, 2)
        },
        listOfCategoriesJob() {
            return this.globals.categoriesJob.filter(c => !c.onlyOrganisationsPro).slice(0, 2)
        },
        listOfCategoriesPro() {
            return this.globals.categoriesJob.filter(c => c.onlyOrganisationsPro).slice(0, 2)
        },
        results() {
            if (!this.selectedCreationType || !this.selectedJob) {
                return false
            }

            if (this.isOrganisationsProfessionnelles) {
                return this.firestoreContent.filter(item => {
                    return item.filterInterest.includes(this.selectedCreationType) && item.filterMetier.includes(this.selectedJob) && item.formationType.includes('pour-professionnel-le-de-laudiovisuel')
                })
            }

            const items = [...this.firestoreContent]
            let filteredItems = items.filter(item => {
                return item.filterInterest.includes(this.selectedCreationType) && item.filterMetier.includes(this.selectedJob)
            })

            if (!filteredItems.length) {
                return []
            }

            return filteredItems
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'sidebarMainStep']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        async togglePanel() {
            document.querySelector('body').classList.remove('menu-main-open')
            document.querySelector('.menu--close').classList.remove('open')
            document.querySelector('body').classList.toggle('sidebar-main-open')

            if (!document.querySelector('body').classList.contains('sidebar-main-open') ) {
                this.setSidebarMainStep(null)
            } else {
                try {
                    const res = await getAllDocs(
                        'Formations',
                        false
                    )
                    let firestoreContent = res
                        firestoreContent = firestoreContent.filter(
                            (item) => item.jsonBody && item.jsonHeader && item.startDate
                        )
                    this.firestoreContent = firestoreContent
                    this.firestoreContentLoaded = true
                } catch (e) {
                    this.firestoreErr = e
                }
            }
        },
        closePanel() {
            this.reset()
            this.setSidebarMainStep(null)

            document.querySelector('body').classList.remove('menu-main-open')
            document.querySelector('.menu--close').classList.remove('open')
            document.querySelector('body').classList.remove('sidebar-main-open')
        },
        selectFilter(type, isOrganisationPro = false) {
            this.isOrganisationsProfessionnelles = isOrganisationPro
            this.sidebarStep = type
        },
        selectCreationType(value) {
            this.selectedCreationType = value
            this.sidebarStep = 'jobs'

            if (this.selectedJob) {
                this.lastSelection = 'creation'
            }
        },
        selectJob(value, isOrganisationPro = false) {
            this.selectedJob = value
            this.sidebarStep = 'creation'
            this.userSelectedOrganisationsPro = isOrganisationPro

            if (this.selectedCreationType) {
                this.lastSelection = 'jobs'
            }
        },
        backToSelections() {
            this.sidebarStep = this.lastSelection

            if (this.lastSelection === 'jobs') {
                this.selectedJob = null
            } else {
                this.selectedCreationType = null
            }
        },
        reset() {
            this.sidebarStep = 'intro'
            this.forceShowAssociations = false
            // this.setSidebarMainStep('intro')
            this.selectedCreationType = null
            this.selectedJob = null
            this.lastSelection = null
        },
        ...mapActions(['setSidebarMainStep']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
