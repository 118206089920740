<template>
    <nav id="navigation-desktop" class="navigation-desktop">
        <!-- <div class="sep-hor-black"></div> -->
        <div class="container-fluid container-main-navigation position-relative">
            <div class="menu-children-wrapper">
                <button
                    @click.prevent.stop=""
                    id="children-menu-toggler-close"
                    class="mb-4 pb-4 font-size-h4 no-style red">
                    <i class="fa-solid fa-chevron-left"></i>
                    {{ $t('Retour au menu') }}
                </button>

                <p class="title-navigation">{{ $t('Nos formations') }}</p>

                <ul
                    v-if="globalsIsLoaded && globals.mainNav.NavigationFormation !== null"
                    class="nav menu-secondary-items-children"
                >
                    <li
                        v-for="(value, index) in globals.mainNav.NavigationFormation.NavigationFormation"
                        :key="'mobile-nav-item-' + index"
                        class="nav-item"
                    >
                        <router-link
                            v-if="value.link.type == 'formationCat' || value.link.type == 'formationType'"
                            class="nav-link"
                            :to="{ path: value.link.filterUrl }"
                            itemtype="https://www.schema.org/SiteNavigationElement"
                        >
                            {{ value.link.title }}
                        </router-link>

                        <router-link
                            v-if="value.link.type == 'formationPage'"
                            class="nav-link"
                            :to="{ path: value.link.slug.fr }"
                            itemtype="https://www.schema.org/SiteNavigationElement"
                        >
                            {{ value.link.itemTitle }}
                        </router-link>
                    </li>

                </ul>

            </div>

            <div class="row">
                <div class="col-left col-12 col-xl-7">
                    <div class="row">
                        <div class="col-12 col-md-6 col-xl-5 mb-5 mb-xl-0">
                            <nav class="Menu-secondary-wrapper">
                                <ul class="nav menu-primary-items d-lg-none">
                                    <li class="nav-item parent-item">
                                        <a
                                            id="children-menu-toggler-open"
                                            class="nav-link nav-link-primary"
                                            @click.prevent.stop=""
                                        >
                                            {{ $t('Nos formations') }}
                                        </a>
                                    </li>
                                </ul>

                                <ul class="nav menu-primary-items d-none d-lg-block">
                                    <li class="nav-item">
                                        <router-link
                                            :to="{ name: 'Formations-fr' }"
                                            class="nav-link nav-link-primary"
                                            itemtype="https://www.schema.org/SiteNavigationElement"
                                            >{{ $t('Formations') }}</router-link
                                        >
                                    </li>
                                </ul>

                                <div v-if="globalsIsLoaded && globals.mainNav.NavigationFormation">
                                    <ul
                                        v-if="globals.mainNav.NavigationFormation !== null"
                                        class="nav menu-secondary-items desktop"
                                    >
                                        <li
                                            v-for="(value, index) in globals.mainNav.NavigationFormation.NavigationFormation"
                                            :key="'secondary-nav-item-' + index"
                                            class="nav-item"
                                        >
                                            <router-link
                                                v-if="value.link.type == 'formationCat' || value.link.type == 'formationType'"
                                                class="nav-link"
                                                :to="{ path: value.link.filterUrl }"
                                                itemtype="https://www.schema.org/SiteNavigationElement"
                                            >
                                                {{ value.link.title }}
                                            </router-link>

                                            <router-link
                                                v-if="value.link.type == 'formationPage'"
                                                class="nav-link"
                                                :to="{ path: value.link.slug.fr }"
                                                itemtype="https://www.schema.org/SiteNavigationElement"
                                            >
                                                {{ value.link.itemTitle }}
                                            </router-link>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>

                        <div class="col-12 col-md-6 col-xl-7">
                            <div
                                v-if="globalsIsLoaded && globals.mainNav.navigation.mainNav"
                                class="Menu-primary-wrapper"
                            >
                                <ul class="nav menu-primary-items">
                                    <li
                                        v-for="(value, index) in globals.mainNav.navigation.mainNav"
                                        :key="'primary-nav-item-' + index"
                                        :class="[value.link.type == 'menuTitle' ? 'title-item' : 'nav-item']"
                                    >
                                        <p
                                            v-if="value.link.type == 'menuTitle'"
                                            class="title-navigation"
                                        >
                                            {{ value.link.data }}
                                        </p>
                                        <router-link
                                            v-if="value.link.type == 'menuLink'"
                                            class="nav-link"
                                            :to="{ path: value.link.data.fr }"
                                            itemtype="https://www.schema.org/SiteNavigationElement"
                                        >
                                            {{ value.link.itemTitle }}
                                        </router-link>
                                        <a
                                            v-if="value.link.type == 'ExternLink'"
                                            class="nav-link"
                                            :href="value.link.data"
                                            itemtype="https://www.schema.org/SiteNavigationElement"
                                        >
                                            {{ value.link.itemTitle }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sep-hor-black-main-nav"></div>

                <div  v-if="globalsIsLoaded" class="col-right col-12 col-xl-5">
                    <p class="title-navigation text-legal">{{ $t('Nous contacter') }}</p>
                    <p class="bold" v-html="globals.contact.inisAddress"></p>
                    <a
                        :href="'tel:' + globals.contact.inisPhone"
                        class="mb-2 d-inline-block"
                        >{{ globals.contact.inisPhone }}</a
                    ><br />
                    <a
                        v-if="globals.contact.inisFormLink !== ''"
                        :href="globals.contact.inisFormLink"
                        target="_blank"
                    >
                        {{ $t('Nous joindre') }}
                    </a>
                    <a
                        v-else
                        :href="'mailto:' + globals.contact.inisEmail"
                    >
                        {{ $t('Nous joindre') }}
                    </a>

                    <div class="mt-4">
                        <SocialsIcons v-if="globalsIsLoaded" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import SocialsIcons from '@/components/Parts/SocialsIcons'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'MenuMain',

    components: {
        SocialsIcons,
    },

    props: {
        siteName: {
            type: String,
            default: '',
        },
    },
    // On loaded...
    mounted() {
        document.querySelector('#children-menu-toggler-open').addEventListener('click', () => {
            document.querySelector('#app').classList.add('menu-children-open')
        }),
        document.querySelector('#children-menu-toggler-close').addEventListener('click', () => {
            document.querySelector('#app').classList.remove('menu-children-open')
        })
    },
    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['content', 'globals']),
    },
    methods: {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
