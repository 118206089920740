const firebaseConfig = {
    production: {
        apiKey: 'AIzaSyB0WJLyCg4BKh3tvLMj31fPxaw3HKSiWRA',
        authDomain: 'inis-96d2b.firebaseapp.com',
        projectId: 'inis-96d2b',
        storageBucket: 'inis-96d2b.appspot.com',
        messagingSenderId: '102825946983',
        appId: '1:102825946983:web:4a490663a90c8ace3c49c2',
    },
    stage: {
        apiKey: "AIzaSyB-g7NwUn6DI0IQeq5J7PSDWhxFNt1ZZqI",
        authDomain: "inis-stage.firebaseapp.com",
        projectId: "inis-stage",
        storageBucket: "inis-stage.appspot.com",
        messagingSenderId: "966198711978",
        appId: "1:966198711978:web:b65f580b1d3d4ea8e762d3"
    },
    local: {
        apiKey: "AIzaSyB-g7NwUn6DI0IQeq5J7PSDWhxFNt1ZZqI",
        authDomain: "inis-stage.firebaseapp.com",
        projectId: "inis-stage",
        storageBucket: "inis-stage.appspot.com",
        messagingSenderId: "966198711978",
        appId: "1:966198711978:web:b65f580b1d3d4ea8e762d3"
    }
}

export default firebaseConfig
