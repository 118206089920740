<template>
    <div class="error-reporting">
        <p>{{ msg }}</p>
    </div>
</template>

<script>
export default {
    name: 'ErrorsReporting',

    props: {
        msg: {
            type: Error,
            default: '',
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.error-reporting {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 14px;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
}
</style>
