import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: {
            bodyClass: 'home',
            lang: 'fr',
            json: 'home',
        },
    },
    // Simple redirect
    {
        path: '/a-propos',
        redirect: '/a-propos-inis',
    },
    // Simple redirect
    {
        path: '/bourses',
        redirect: '/bourses-et-aide-financiere',
    },
    {
        path: '/a-propos-inis',
        name: 'About-fr',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            bodyClass: 'template-about dark',
            lang: 'fr',
            json: 'aboutUs',
        },
    },
    {
        path: '/parcours-personalise-formation',
        name: 'parcours-personalise-fr',
        component: () => import(/* webpackChunkName: "ParcoursPersonalise" */ '../views/ParcoursPersonalise.vue'),
        meta: {
            bodyClass: 'template-parcours-personalise light',
            lang: 'fr',
            json: 'ParcoursPersonalise',
        },
    },
    {
        path: '/mutuelle',
        name: 'Mutuelle-fr',
        component: () => import(/* webpackChunkName: "about" */ '../views/ModeleMutuelle.vue'),
        meta: {
            bodyClass: 'template-mutuelle light',
            lang: 'fr',
            json: 'mutuelle',
        },
    },
    {
        path: '/choisir-inis',
        name: 'choisirLinis-fr',
        component: () => import(/* webpackChunkName: "choisirLinis" */ '../views/choisirLinis.vue'),
        meta: {
            bodyClass: 'template-choisir-inis dark',
            lang: 'fr',
            json: 'choisirLinis',
        },
    },
    {
        path: '/calendrier',
        name: 'Calendrier-fr',
        component: () => import(/* webpackChunkName: "calendrier" */ '../views/Calendrier.vue'),
        meta: {
            bodyClass: 'calendrier',
            lang: 'fr',
            json: 'calendrier',
            firebaseCollection: 'Formations',
            firebaseDocumentBySlug: false,
        },
    },
    {
        path: '/nos-formations',
        name: 'Formations-fr',
        component: () =>
            import(/* webpackChunkName: "formations" */ '../views/Formations/Index.vue'),
        meta: {
            bodyClass: 'formations-index',
            lang: 'fr',
            json: 'nosFormations',
            firebaseCollection: 'Formations',
            firebaseDocumentBySlug: false,
        },
    },
    {
        path: '/formations-sur-mesure',
        name: 'FormationSurMesure-fr',
        component: () =>
            import(/* webpackChunkName: "FormationSurMesure" */ '../views/ModeleFlexible.vue'),
        meta: {
            bodyClass: 'formations-sur-mesure template-flexible light',
            lang: 'fr',
            json: 'surMesure'
        },
    },
    {
        path: '/formation/:slug',
        name: 'Formation-fr',
        component: () =>
            import(/* webpackChunkName: "formation" */ '../views/Formations/Entry.vue'),
        meta: {
            bodyClass: 'formations-single',
            lang: 'fr',
            json: 'nosFormations',
            firebaseCollection: 'Formations',
            firebaseDocumentBySlug: true,
        },
    },
    {
        path: '/projets-etudiants',
        name: 'Projets-fr',
        component: () =>
            import(/* webpackChunkName: "projets" */ '../views/Projets/Index.vue'),
        meta: {
            bodyClass: 'productions-index',
            lang: 'fr',
            json: 'projets',
            firebaseCollection: 'Productions',
            firebaseDocumentBySlug: false,
        },
    },
    {
        path: '/projets-etudiants/:slug',
        name: 'Projet-fr',
        component: () =>
            import(/* webpackChunkName: "projet" */ '../views/Projets/Entry.vue'),
        meta: {
            bodyClass: 'production-single',
            lang: 'fr',
            json: 'projets',
            firebaseCollection: 'Productions',
            firebaseDocumentBySlug: true,
        },
    },
    {
        path: '/emplois',
        name: 'Emplois-fr',
        component: () => import(/* webpackChunkName: "emplois" */ '../views/Emplois/Index.vue'),
        meta: {
            bodyClass: 'emplois-index',
            lang: 'fr',
            json: 'emplois',
        },
    },
    {
        path: '/emploi/:slug',
        name: 'Emploi-fr',
        component: () => import(/* webpackChunkName: "emploi" */ '../views/Emplois/Entry.vue'),
        meta: {
            bodyClass: 'emploi-single',
            lang: 'fr',
            json: 'emploiEntry',
            slug: true,
        },
    },
    {
        path: '/bottin',
        name: 'Membres-fr',
        component: () => import(/* webpackChunkName: "membres" */ '../views/Membres/Index.vue'),
        meta: {
            bodyClass: 'membres-index',
            lang: 'fr',
            json: 'membres',
            firebaseCollection: 'Membres',
            firebaseDocumentBySlug: false,
        },
    },
    {
        path: '/bottin/:slug',
        name: 'Membre-fr',
        component: () => import(/* webpackChunkName: "membre" */ '../views/Membres/Entry.vue'),
        meta: {
            bodyClass: 'membre-single light',
            lang: 'fr',
            json: 'membres',
            firebaseCollection: 'Membres',
            firebaseDocumentBySlug: true,
            useFirebaseAsMeta: true,
        },
    },
    {
        path: '/nouvelles',
        name: 'Nouvelles-fr',
        component: () => import(/* webpackChunkName: "nouvelles" */ '../views/Nouvelles/Index.vue'),
        meta: {
            bodyClass: 'nouvelles-index dark',
            lang: 'fr',
            json: 'nouvelles',
        },
    },
    {
        path: '/nouvelle/:slug',
        name: 'Nouvelle-fr',
        component: () => import(/* webpackChunkName: "nouvelle" */ '../views/Nouvelles/Entry.vue'),
        meta: {
            bodyClass: 'nouvelle-single light',
            lang: 'fr',
            json: 'nouvelleEntry',
            slug: true,
        },
    },
    {
        path: '/blogue',
        name: 'blogue-fr',
        component: () => import(/* webpackChunkName: "blogue" */ '../views/Blogue/Index.vue'),
        meta: {
            bodyClass: 'blog-index dark',
            lang: 'fr',
            json: 'blogue',
        },
    },
    {
        path: '/blogue/:slug',
        name: 'blogue-publication-fr',
        component: () => import(/* webpackChunkName: "blogue-publication" */ '../views/Blogue/Entry.vue'),
        meta: {
            bodyClass: 'blog-single light',
            lang: 'fr',
            json: 'blogEntry',
            slug: true,
        },
    },
    {
        path: '/mon-panier',
        name: 'Panier-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Panier.vue'),
        meta: {
            bodyClass: 'panier light',
            lang: 'fr',
            json: 'home',
        },
    },
    {
        path: '/pages-protegees/:slug',
        name: 'Pages-Protegees-fr',
        component: () => import(/* webpackChunkName: "Pages-Protegees-fr" */ '@/views/ModeleFlexibleProtege.vue'),
        meta: {
            bodyClass: 'template-flexible light',
            lang: 'fr',
            json: 'pages-protegees',
            slug: true,
            isDynamicPages: true,
        },
    },
    {
        path: '/:slug',
        name: 'Pages-Dynamiques-fr',
        component: () => import(/* webpackChunkName: "Pages-Dynamiques-fr" */ '@/views/ModeleFlexible.vue'),
        meta: {
            bodyClass: 'template-flexible light',
            lang: 'fr',
            json: 'pagesDynamiques',
            slug: true,
            isDynamicPages: true,
        },
    },
    {
        path: '/blogue-categorie/:slug',
        name: 'blogue-categorie-fr',
        component: () => import(/* webpackChunkName: "blogue" */ '../views/Blogue/IndexBlogType.vue'),
        meta: {
            bodyClass: 'blog-index dark',
            lang: 'fr',
            json: 'blogue',
            slug: false,
        },
    },
    // {
    //     path: '/evenement/:slug',
    //     name: 'Evenement-fr',
    //     component: () => import(/* webpackChunkName: "evenement" */ '../views/evenements/Entry.vue'),
    //     meta: { lang: 'fr', json: 'evenementEntry', slug: true },
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    hashbag: true,
    scrollBehavior() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    x: 0,
                    y: 0,
                    // behavior: 'smooth',
                })
            }, 950)
        })
    }
})

router.beforeEach((to, from, next) => {

    Store.dispatch('setFromUrl', from.path)

    if( document.querySelector('#animation-curtain')){
        document.querySelector('#animation-curtain').classList.add('animate')

        setTimeout(() => {
            window.scrollTo(0,0)
            document.querySelector('.menu--close').classList.remove('open')
        }, 1250)

        setTimeout(() => {
            document.querySelector('#animation-curtain').classList.remove('animate')
            // document.querySelector('#loading-logo').classList.remove('animate')
        }, 3500)
    }

    if( document.querySelector('#transition-curtain')){
        document.querySelector('#transition-curtain').classList.add('animate')

        setTimeout(() => {
            window.scrollTo(0,0)
            document.querySelector('.menu--close').classList.remove('open')
        }, 1250)

        setTimeout(() => {
            document.querySelector('#transition-curtain').classList.remove('animate')
            // document.querySelector('#loading-logo').classList.remove('animate')
        }, 4500)
    }

    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        // Store.dispatch('resetContent')
        Store.dispatch('setBgTheme', '')
        Store.dispatch('setFirestoreContentLoaded', false)

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        if (meta.useFirebaseAsMeta) {
            // Store.dispatch('getEntryContentFromFirebase', { firebaseCollection: meta.firebaseCollection, slug: params.slug })
            Store.dispatch('getContent', { lang: meta.lang, slug, type, item, firebaseCollection: meta.firebaseCollection })
        } else {
            Store.dispatch('getContent', { lang: meta.lang, slug, type, item, firebaseCollection: meta.firebaseCollection })
        }

        Store.dispatch('getCart')

        next()

    }, 1000)
})

export default router
