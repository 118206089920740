import Vue from 'vue'
import Vuex from 'vuex'
import Store from '@/store'

import { getDocById, getDocBySlug } from '@/firebase/init'
import { parseFirestoreData } from '@/plugins/Helpers'

const utf8 = require('utf8')

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        locale: {
            locale: null,
            loaded: false,
        },

        fromUrl: '/',

        content: {
            loading: false,
            ready: false,
            data: null,
        },

        contentIsLoaded: false,
        firestoreContentLoaded: false,
        firestoreContentData: null,

        errorsReportMsg: null,

        globals: {
            loaded: false,
            loading: null,
            data: {
                menuItems: [],
                sideLink: {},
                companyEmail: '',
                companyAddress: {},
                companyAddressLabel: '',
                companySocials: [],
                companyPhoneNumber: '',
                footer: [],
            },
        },

        globalsIsLoaded: false,

        seo: {
            title: null,
            titleRaw: {
                1: '',
                2: '',
            },
            description: null,
            descriptionRaw: '',
            keywords: [],
            score: '',
            social: {
                twitter: {
                    handle: '',
                    title: '',
                    imageId: '',
                    description: '',
                },
                facebook: {
                    handle: '',
                    title: '',
                    imageId: '',
                    description: '',
                },
            },
            advanced: {
                robots: [],
                canonical: '',
            },
        },

        urls: {
            en: '',
            fr: '',
        },

        cart: null,

        bgTheme: '',

        sidebarMainStep: null,

        formationContent: {},
        popupForm: false,
    },

    getters: {
        oppositeLocale: state => (state.locale.locale === 'fr' ? 'en' : 'fr'),
        localeLoaded: state => state.locale.loaded,
        locale: state => state.locale.locale,
        fromUrl: state => state.fromUrl,
        errorsReportMsg: state => state.errorsReportMsg,
        getRoute: state => (route, params) => {
            return {
                name: `${route}Page-${state.locale.locale}`,
                ...(params && { params: { ...params } }),
            }
        },

        // CURRENT PAGE
        contentLoading: state => state.content.loading,
        firestoreContentLoaded: state => state.firestoreContentLoaded,
        firestoreContentData: state => state.firestoreContentData,
        content: state => state.content.data,
        pageReady: state => state.content.ready,

        // HEADER, FOOTER
        globalsLoaded: state => state.globals.loaded,
        globalsLoading: state => state.globals.loading,
        globals: state => state.globals.data,

        seo: state => state.seo,
        urls: state => state.urls,
        cart: state => state.cart,
        bgTheme: state => state.bgTheme,
        sidebarMainStep: state => state.sidebarMainStep,
        formationContent: state => state.formationContent,
        popupForm: state => state.popupForm,
    },

    actions: {
        async getGlobals({ commit }) {
            commit('GLOBALS_LOADING', true)
            commit('GLOBALS_LOADED', false)
            try {
                const {
                    contact,
                    mainNav,
                    globalTestimonials,
                    footerNavCol01,
                    footerNavCol02,
                    categoriesCreation,
                    categoriesFormations,
                    categoriesJob,
                    formationTabsTitleSections,
                    categoriesLength,
                    categoriesFormatType,
                    salesforce,
                    inisGlobalElement,
                    associationsSyndicats,
                    blogType
                } = await Vue.prototype.$craft.getGlobals()
                if (
                    contact &&
                    mainNav &&
                    globalTestimonials &&
                    footerNavCol01 &&
                    footerNavCol02 &&
                    categoriesCreation &&
                    categoriesFormations &&
                    categoriesJob &&
                    formationTabsTitleSections &&
                    categoriesLength &&
                    categoriesFormatType &&
                    salesforce &&
                    inisGlobalElement &&
                    associationsSyndicats &&
                    blogType
                ) {
                    commit('SET_GLOBALS', {
                        contact,
                        mainNav,
                        globalTestimonials,
                        footerNavCol01,
                        footerNavCol02,
                        categoriesCreation,
                        categoriesFormations,
                        categoriesJob,
                        formationTabsTitleSections,
                        categoriesLength,
                        categoriesFormatType,
                        salesforce,
                        inisGlobalElement,
                        associationsSyndicats,
                        blogType
                    })
                    commit('GLOBALS_LOADED', true)
                    return {
                        contact,
                        mainNav,
                        globalTestimonials,
                        footerNavCol01,
                        footerNavCol02,
                        categoriesCreation,
                        categoriesFormations,
                        categoriesJob,
                        formationTabsTitleSections,
                        categoriesLength,
                        categoriesFormatType,
                        salesforce,
                        inisGlobalElement,
                        associationsSyndicats,
                        blogType
                    }
                }
                throw new Error('Cannot get globals')
            } catch (error) {
                console.error(error)
            } finally {
                commit('GLOBALS_LOADING', false)
            }
        },

        async getContent({ commit, dispatch }, { lang, slug, type, item, firebaseCollection }) {
            let element = slug

            commit('CONTENT_LOADING', true)

            try {
                const { data } = await Vue.prototype.$craft.getContentBySlug(
                    element,
                    type,
                    lang,
                    item,
                    firebaseCollection
                )
                // console.log({data});
                if (data) {
                    if (data.seo) {
                        dispatch('setSeo', data.seo)
                    }
                    if (data.urls) {
                        dispatch('setUrls', data.urls)
                    }
                    if (data.page || data.member || data.career || data.media) {
                        commit('SET_CONTENT', data.page || data.member || data.career || data.media)
                        commit('CONTENT_LOADING', false)
                        // commit('FIRESTORE_CONTENT_DATA', data.firebaseCollection)
                        // commit('FIRESTORE_CONTENT_LOADED', true)
                        return data
                    }
                }
                throw new Error('Cannot get content for this page')
            } catch (error) {
                console.error(error)
                // Broswer History Push a new url
                window.history.pushState({}, '', Store.getters['fromUrl']);
                setTimeout(() => {
                    // set <meta name="prerender-status-code" content="404"> to the head of the page
                    const meta = document.createElement('meta')
                    meta.name = 'prerender-status-code'
                    meta.content = '404'
                    document.getElementsByTagName('head')[0].appendChild(meta)

                    location.href = '/404'
                }, 175)
            }
        },

        async getEntryContentFromFirebase({ commit, dispatch }, { firebaseCollection, slug }) {
            // let element = slug

            commit('CONTENT_LOADING', true)

            // console.log({firebaseCollection, slug});

            try {
                const data = await getDocBySlug(
                    firebaseCollection,
                    slug
                )
                if (data) {
                    // console.log({data}, data[0]);
                    let fireStoreData = data[0]
                    parseFirestoreData(fireStoreData)
                    dispatch('setFirestoreContentLoaded', true)
                    dispatch('setFirestoreContentData', fireStoreData)

                    // console.log({fireStoreData});

                    const seo = {
                        "titleRaw":
                            {
                                1: fireStoreData.jsonBody.metaTitle,
                                2: "| Inis"
                            },
                            "descriptionRaw": fireStoreData.jsonBody.metaDescription,
                            "keywords":[],
                            "score": "",
                            "description": fireStoreData.jsonBody.metaDescription,
                            "title": fireStoreData.jsonBody.metaTitle,
                        }

                    dispatch('setSeo', seo)

                    commit('SET_CONTENT', data[0])
                    commit('CONTENT_LOADING', false)

                    return data[0]
                }
                throw new Error('Cannot get content for this page')
            } catch (error) {
                console.error(error)
                // Broswer History Push a new url
                window.history.pushState({}, '', Store.getters['fromUrl']);
                setTimeout(() => {
                    // set <meta name="prerender-status-code" content="404"> to the head of the page
                    const meta = document.createElement('meta')
                    meta.name = 'prerender-status-code'
                    meta.content = '404'
                    document.getElementsByTagName('head')[0].appendChild(meta)

                    location.href = '/404'
                }, 175)
            }
        },

        resetContent({ commit }) {
            commit('SET_CONTENT', null)
        },

        setContentLoading({ commit }, loading) {
            commit('CONTENT_LOADING', loading)
        },

        setFirestoreContentLoaded({ commit }, loaded) {
            commit('FIRESTORE_CONTENT_LOADED', loaded)
        },

        setFirestoreContentData({ commit }, data) {
            commit('FIRESTORE_CONTENT_DATA', data)
        },

        setContent({ commit }, payload) {
            commit('SET_CONTENT', payload)
        },

        setLocale({ commit, getters }, locale) {
            commit('SET_LOCALE', locale)
            if (!getters.localeLoaded) {
                commit('SET_LOCALE_LOADED')
            }
        },

        setFromUrl({ commit }, fromUrl) {
            commit('SET_FROM_URL', fromUrl)
        },

        setSeo({ commit }, seo) {
            commit('SET_SEO', seo)
        },

        setUrls({ commit }, urls) {
            commit('SET_URLS', urls)
        },

        setBgTheme({ commit }, value) {
            commit('SET_BG_THEME', value)
        },

        async getCart({ commit }, cartIdRef = null) {
            let cartData = null
            if (cartIdRef || window.localStorage.getItem('inis_cart_id')) {
                const cartId = cartIdRef ? cartIdRef : window.localStorage.getItem('inis_cart_id')
                cartData = await getDocById('Paniers', cartId)
                if (cartData) {
                    cartData = {
                        id: cartId, ...cartData.data()
                    }
                }
            }
            commit('SET_CART', cartData)
        },

        async validateCart({ commit }, cart) {
            const cartObject = {...cart}
            const items = cartObject.selection
            const newSelection = []

            const formatItem = async (item) => {
                const itemRef = await getDocById('Formations', item.formationId)

                if (!itemRef) {
                    return
                }

                const itemRefData = itemRef.data()

                // Base64 Decode
                itemRefData.jsonHeader = atob(itemRefData.jsonHeader)
                // Utf8 Decode
                itemRefData.jsonHeader = utf8.decode(
                    itemRefData.jsonHeader
                )
                // Json Parse
                itemRefData.jsonHeader = JSON.parse(
                    itemRefData.jsonHeader
                )

                newSelection.push({
                    formationId: item.formationId,
                    name: item.name,
                    numberSelected: parseInt(item.numberSelected, 10),
                    type: item.type,
                    priceRegular: itemRefData.jsonHeader.priceRegular,
                    priceReduced: itemRefData.jsonHeader.priceReduced,
                    slug: itemRefData.slug,
                    preInscription: itemRefData.preInscription,
                    subscriptionDate: itemRefData.subscriptionDate,
                    startDate: itemRefData.startDate,
                    deactivated: itemRefData.deactivated,
                    placeAvailable: itemRefData.placeAvailable,
                    lms: itemRefData.lms ? itemRefData.lms : false ,
                })
            }

            const promises = []
            for (const item of items) {
                promises.push(formatItem(item))
            }

            await Promise.all(promises)
            cartObject.selection = newSelection

            commit('SET_CART', cartObject)
        },

        setErrorsReportMsg({ commit }, msg) {
            commit('SET_ERRORS_REPORT_MSG', msg)
        },

        setSidebarMainStep({ commit }, step) {
            commit('SET_SIDEBAR_MAIN_STEP', step)
        },

        setFormationContent({ commit }, content) {
            commit('SET_FORMATION_CONTENT', content)
        },

        setPopupForm({ commit }, form) {
            commit('SET_POPUP_FORM', form)
        },

        togglePopupForm({ commit }, form) {
            commit('SET_POPUP_FORM', form)
        },
    },

    mutations: {
        ['GLOBALS_LOADED'](state, payload) {
            state.globals.loaded = payload
            state.globalsIsLoaded = payload
        },

        ['GLOBALS_LOADING'](state, payload) {
            state.globals.loading = payload
        },

        ['SET_GLOBALS'](state, payload) {
            state.globals.data = payload
        },

        ['CONTENT_LOADING'](state, payload) {
            state.content.loading = payload
            state.contentIsLoaded = !payload
        },

        ['FIRESTORE_CONTENT_LOADED'](state, value) {
            state.firestoreContentLoaded = value
        },

        ['FIRESTORE_CONTENT_DATA'](state, value) {
            state.firestoreContentData = value
        },

        ['SET_CONTENT'](state, payload) {
            state.content.data = payload
        },

        ['SET_LOCALE'](state, locale) {
            state.locale.locale = locale
        },

        ['SET_FROM_URL'](state, fromUrl) {
            state.fromUrl = fromUrl
        },

        ['SET_LOCALE_LOADED'](state) {
            state.locale.loaded = true
        },

        ['SET_SEO'](state, seo) {
            if (!seo || !seo.titleRaw) {
                return
            }
            state.seo = { ...seo, description: seo.descriptionRaw, title: seo.titleRaw['1'] }
        },

        ['SET_URLS'](state, urls) {
            state.urls = urls
        },

        ['SET_CART'](state, cartData) {
            state.cart = cartData
        },

        ['SET_BG_THEME'](state, value) {
            state.bgTheme = value
        },

        ['SET_ERRORS_REPORT_MSG'](state, msg) {
            state.errorsReportMsg = msg
        },

        ['SET_SIDEBAR_MAIN_STEP'](state, step) {
            state.sidebarMainStep = step
        },

        ['SET_FORMATION_CONTENT'](state, content) {
            state.formationContent = content
        },

        ['SET_POPUP_FORM'](state, form) {
            state.popupForm = form
        }
    },

    modules: {},
})
