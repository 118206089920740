<template>
    <footer class="site-footer section-aditional-infos pb-4">
        <div class="">
            <div class="row g-0">
                <div class="col-12 col-xxl-5 d-flex flex-column">
                    <div class="newsletter-wrapper py-5">
                        <h3>
                            {{ $t('Restons') }} <br />
                            {{ $t('connectés') }}
                        </h3>

                        <!-- <router-link to="/no">No</router-link> -->

                        <p class="text-white bold">
                            {{ $t('Inscrivez-vous à notre liste d’envoi') }}
                        </p>

                        <div class="py-5">
                            <!-- Newsletter Form : Cyberimpact -->
                            <form
                                action=""
                                method="post"
                                target="_blank"
                                class="newsletter-form"
                                @submit="submitNewsletterForm"
                            >
                                <input type="email" name="email" id="email" placeholder="Votre adresse courriel" required="required">
                                <input type="submit" value="Envoyer">
                            </form>
                            <div class="form-success" v-if="formMessage">
                                <p>{{formMessage}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-xxl-7">
                    <!-- Desktop footer navigation -->
                    <div class="footer-nav-wrapper pb-5">
                        <MenusFooter v-if="globalsIsLoaded" />
                        <EscalierInis />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="footer-info pt-4 mt-auto">
                        <p class="mb-0">© L'inis - {{ theYear }}</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import MenusFooter from '@/components/Parts/MenusFooter'
import EscalierInis from '@/components/svgs/escalierInis'
import { mapState, mapGetters } from 'vuex'
import dayjs from 'dayjs'

import { addNewMemberToCyberimpact } from '@/firebase/init'

// import axios from 'axios'

export default {
    name: 'Footer',

    data() {
        return {
            err: '',
            formMessage: '',
        }
    },

    components: {
        MenusFooter,
        EscalierInis
    },

    props: {
        siteName: {
            type: String,
            default: '',
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['content', 'globals']),
        theYear() {
            return dayjs().year()
        },
    },

    methods: {
        async submitNewsletterForm(event) {
            event.preventDefault()

            // Add member to CyberImpact
            const email = document.querySelector('#email').value

            try {
                await addNewMemberToCyberimpact({email})
                document.querySelector('#email').value = ''
                document.querySelector('.newsletter-form').classList.add('hide')
                this.formMessage = 'Merci pour votre inscription!'
            } catch (error) {
                this.err = error
                document.querySelector('#email').value = ''
                this.formMessage = 'Une erreur est survenue. Veuillez réessayer.'
            }
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
