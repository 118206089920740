<template>
    <div class="popup-form" v-if="popupForm" @click.prevent="closePopup()">
        <div class="popup-form-content" @click.stop>
            <header class="popup-form-header">
                <h4 class="title-programs mb-0">Liste de rappel</h4>
                <button
                    class="btn-close no-style"
                    @click.prevent="closePopup()"
                >
                    <span class="icon-close"><i class="fa-regular fa-xmark-large"></i></span>
                </button>
            </header>

            <div class="popup-form-body" @click.stop>
                <div class="popup-form-body-error-wrapper" data-content-success v-if="popupFormResponse.status === 'error'">
                    <p>{{$t('Certaines informations n’ont pu être traitées. Veuillez les remplir correctement:')}}</p>
                </div>
                <div class="popup-form-body-success-wrapper" data-content-error v-if="popupFormResponse.status === 'success'">
                    <i class="fa-solid fa-circle-check sucess-icon"></i>
                    <h4 class="mb-4">{{$t('Votre formulaire a été envoyé avec succès')}}</h4>
                    <p class="mb-2">{{$t('Vous devriez recevoir une confirmation bientôt.')}}</p>
                    <p>{{$t('Nous vous aviserons dès que la formation sera offerte de nouveau.')}}</p>
                    <button
                        class="button max-275 mt-4 mx-auto"
                        @click.prevent="closePopup()"
                    >
                        {{$t('Fermer la fenêtre')}}
                    </button>
                </div>
                <div class="popup-form-body-content" data-content-form v-else>
                    <form id="salesforce-form-subscription" class="formation-rappel-form" action="https://inis.my.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" @submit="sendForm($event)">
                        <input type="hidden" name="retURL" value="https://www.inis.qc.ca/">

                        <input type="hidden" id="oid" name="oid" value="00D5f000003y2Nj">
                        <input type="hidden" id="formationCreationId" name="00NAf000000Of08" :value="formationContent.id" v-if="formationContent.formationType === 'creation'">
                        <input type="hidden" id="formationId" name="00NAf000000Of03" :value="formationContent.id" v-else>

                        <input type="hidden" id="lead_source" name="lead_source" value="Liste de rappel">

                        <div class="mb-4">
                            <h5>{{formationContent.title}}</h5>
                            <p>* {{$t('Information requise')}}</p>
                        </div>

                        <label for="first_name" class="required">{{$t('Prénom')}}</label>
                        <input  id="first_name" maxlength="40" name="first_name" type="text" required="required"/><br>

                        <label for="last_name" class="required mt-3">{{$t('Nom')}}</label>
                        <input  id="last_name" maxlength="80" name="last_name" type="text" required="required"/><br>

                        <label for="email" class="required mt-3">{{$t('Adresse courriel')}}</label>
                        <input  id="email" maxlength="80" name="email" type="email" required="required"/><br>

                        <label for="phone" class="mt-3">{{$t('Téléphone')}}</label>
                        <input  id="phone" maxlength="40" name="phone" type="text" v-maska="'###-###-####'" /><br>
                        <br />
                        <div class="checkbox-wrapper">
                            <input  id="00NAf000000Oezy" name="00NAf000000Oezy" type="checkbox" value="1" /><label for="00NAf000000Oezy">{{$t('Je désire être avisé·e par courriel lorsque cette formation sera à nouveau offerte.')}}</label>
                            <p class="error-message" v-if="checkBoxErrMsg">{{checkBoxErrMsg}}</p>
                        </div>
                        <br>

                        <input type="submit" name="submit" value="Soumettre" class="button hover-stroke-red mt-3">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { maska } from 'maska'
import { handleNewSalesforceFormSubscription } from '@/firebase/init'

export default {
    props: {
        popupForm: {
            type: Boolean,
            required: true,
        },
        formationContent: {
            type: Object,
            required: true,
        },
    },

    directives: { maska },

    name: 'FormationSubForm',

    data() {
        return {
            isSending: false,
            checkBoxErrMsg: '',
            popupFormResponse: {
                status: '',
                message: '',
            },
        };
    },

    methods: {
        ...mapActions(['togglePopupForm']),

        closePopup() {
            this.popupFormResponse = {
                status: '',
                message: '',
            };

            this.togglePopupForm(false);
        },

        async sendForm(e) {
            e.preventDefault()

            this.checkBoxErrMsg = ''

            if (this.isSending) {
                return
            }

            if (!document.querySelector('[name="00NAf000000Oezy"]').checked) {
                this.checkBoxErrMsg = 'Vous devez confirmer le champs ci-dessus pour vous inscrire à une liste de rappel.'
                return
            }

            this.isSending = true

            const form = document.querySelector('#salesforce-form-subscription');

            // console.log({formUrl});

            // const formData = new FormData()
            const jsonData = {}
            // formData.append('oid', form.querySelector('#oid').value)
            jsonData.oid = form.querySelector('#oid').value

            // formData.append('lead_source', form.querySelector('#lead_source').value)
            jsonData.lead_source = form.querySelector('#lead_source').value

            if (form.querySelector('#formationCreationId')) {
                // formData.append('00NAf000000Of08', form.querySelector('#formationCreationId').value)
                jsonData['00NAf000000Of08'] = form.querySelector('#formationCreationId').value
            }
            if (form.querySelector('#formationId')) {
                // formData.append('00NAf000000Of03', form.querySelector('#formationId').value)
                jsonData['00NAf000000Of03'] = form.querySelector('#formationId').value
            }

            // formData.append('first_name', form.querySelector('#first_name').value)
            jsonData.first_name = form.querySelector('#first_name').value

            // formData.append('last_name', form.querySelector('#last_name').value)
            jsonData.last_name = form.querySelector('#last_name').value

            // formData.append('email', form.querySelector('#email').value)
            jsonData.email = form.querySelector('#email').value

            // formData.append('phone', form.querySelector('#phone').value)
            jsonData.phone = form.querySelector('#phone').value ? form.querySelector('#phone').value : ''

            if (document.querySelector('[name="00NAf000000Oezy"]').checked) {
                // formData.append('00NAf000000Oezy', form.querySelector('[name="00NAf000000Oezy"]').value)
                jsonData['00NAf000000Oezy'] = form.querySelector('[name="00NAf000000Oezy"]').value
            }

            // formData.append('retURL', form.querySelector('[name="retURL"]').value)
            jsonData.retURL = form.querySelector('[name="retURL"]').value

            try {
                await handleNewSalesforceFormSubscription(jsonData)
                this.popupFormResponse.status = 'success'
                this.popupFormResponse.msg = 'Merci...'
            } catch (error) {
                this.popupFormResponse.status = 'error'
                this.popupFormResponse.msg = 'Error...'
            } finally {
                setTimeout(() => {
                    form.reset()
                    this.isSending = false
                }, 3000)
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .error-message {
        color: red;
        font-size: 12px;
        text-align: left;
        margin: 0;
        padding: 0;
        padding-top: 15px;
    }
</style>
