<template>
    <div id="app" :class="[$route.meta.bodyClass ? $route.meta.bodyClass : '', pageIsLoaded ? 'app-page-is-loaded' : 'app-page-is-loading', 'navbar-show', isIframed ? 'is-iframed': '']">
        <!-- Content -->
        <div id="page">
            <div id="page-wrapper">
                <!-- navigation menu content here -->
                <MenuMain />

                <!-- NavBar -->
                <NavBar />

                <PageLoader v-if="!pageIsLoaded" />

                <router-view />

                <!-- Footer -->
                <Footer :siteName="'L\'inis'" page-transition-group />
            </div>

            <div id="page-sidebar"></div>
            <SidebarMain />

            <AlertModule />

        </div>

        <!-- Errors Reporting -->
        <ErrorsReporting v-if="isDebug && errorsReportMsg" :msg="errorsReportMsg" />

        <!-- FormationSubForm -->
        <FormationSubForm :popupForm="popupForm" :formationContent="formationContent" />

        <!-- <div id="animation-curtain"><LogoInis /></div> -->

        <div id="site-load-curtain"><StairInis /></div>
        <div id="transition-curtain"><StairInis /></div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { loadPageAssets } from '@/plugins/pageAssetsLoader'

import NavBar from '@/components/NavBar'
import StairInis from '@/components/svgs/stairInis'
import FormationSubForm from '@/components/Parts/FormationSubForm'
import SidebarMain from '@/components/SidebarMain'
import MenuMain from '@/components/MenuMain'
import Footer from '@/components/Footer'
import PageLoader from '@/components/PageLoader'
import ErrorsReporting from '@/components/helpers/ErrorsReporting'
import AlertModule from '@/components/AlertModule'

import AOS from 'aos'

export default {
    components: {
        NavBar,
        Footer,
        PageLoader,
        SidebarMain,
        MenuMain,
        ErrorsReporting,
        FormationSubForm,
        StairInis,
        AlertModule
    },

    data() {
        return {
            isDebug: 0,
            isIframed: false,
        }
    },

    watch: {
        pageIsLoaded: {
            async handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    if (this.$route.query.iframe && parseInt(this.$route.query.iframe) === 1) {
                        this.isIframed = true
                    }

                    setTimeout(async () => {
                        await loadPageAssets();

                        setTimeout(function () {
                            AOS.init({
                                // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
                                offset: 120, // offset (in px) from the original trigger point
                                delay: 50,
                                duration: 1000, // values from 0 to 3000, with step 50ms
                            })
                        }, 745);
                    }, 275)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            if (this.$route && this.$route.meta.firebaseCollection) {
                return this.contentIsLoaded && this.globalsIsLoaded && this.firestoreContentLoaded
            }

            if(this.$route && !this.$route.meta.firebaseCollection) {
                return this.contentIsLoaded && this.globalsIsLoaded
            }

            return false
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'popupForm', 'formationContent']),
        ...mapGetters(['seo', 'content', 'globals', 'errorsReportMsg', 'firestoreContentLoaded', 'firestoreContentData']),
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:locale',
                    property: 'og:locale',
                    content: this.$route.meta.lang,
                },
                {
                    vmid: 'og:type',
                    property: 'og:type',
                    content: 'website',
                },
                {
                    vmid: 'twitter:card',
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    vmid: 'twitter:title',
                    name: 'twitter:title',
                    content: this.seo.title,
                },
                {
                    vmid: 'twitter:description',
                    name: 'twitter:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
                {
                    vmid: 'home',
                    rel: 'home',
                    href: this.$route.meta.lang === 'fr' ? '/' : '/en',
                },
            ],
        }
    },

    mounted() {
        this.isDebug = parseInt(process.env.VUE_APP_DEBUG)

        var prevScrollpos = window.pageYOffset
        var gap = 0;
        var startingPoint = 0;
        var retract = false;
        var afterWindowScroll = false;
        var app = document.querySelector('#app');
        window.addEventListener('scroll', function () {
            var currentScrollPos = window.pageYOffset

            if(app.classList.contains('navbar-show') && gap > 175 && afterWindowScroll){
                retract = true;
                startingPoint = currentScrollPos;
                gap = 0;
            }else if (app.classList.contains('navbar-show') && app.classList.contains('window-scroll') && afterWindowScroll){
                gap = currentScrollPos - startingPoint;
                retract = false;
            }else{
                startingPoint = currentScrollPos;
                gap = 0;
                retract = false;
            }

            if (window.pageYOffset > 125) {
                app.classList.add('window-scroll');
                afterWindowScroll = true;
            } else {
                app.classList.remove('window-scroll');
                afterWindowScroll = false;
            }

            if (prevScrollpos > currentScrollPos) {
                app.classList.add('navbar-show');
            } else if(!afterWindowScroll && window.pageYOffset > 125){
                app.classList.remove('navbar-show');
                app.classList.remove('sidebar-main-open');
                app.classList.remove('menu-main-open');
                app.classList.remove('open');
            }else if(retract && afterWindowScroll && window.pageYOffset > 125){
                app.classList.remove('navbar-show');
                app.classList.remove('sidebar-main-open');
                app.classList.remove('menu-main-open');
                app.classList.remove('open');
            }
            prevScrollpos = currentScrollPos
        })
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

#app.is-iframed {
    #page-sidebar, .sidebar, #navigation-desktop, .navbar-main, .site-footer, .link-arrow {
        display: none;
    }

    .membre-single-wrapper {
        padding: 0;
    }

    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .justify-content-center {
        width: 100%;
        padding: 0;
    }

    .col-md-10 {
        width: 100%;
        padding: 0 10px;
    }

    .container-xxl {
        max-width: 100%;
    }

    .hero-background.eggshell {
        background-color: #fff;
    }

    .padding-container-xs {
        padding: 0;
    }
}

@media print {
    #page-sidebar, .sidebar, #navigation-desktop, .navbar-main, .site-footer, .link-arrow {
        display: none;
    }

    .membre-single-wrapper {
        padding: 0;
    }

    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .justify-content-center {
        width: 100%;
        padding: 0;
    }

    .col-md-10 {
        width: 100%;
        padding: 0;
    }

    .container-xxl {
        max-width: 100%;
    }

    .hero-background.eggshell {
        background-color: #fff;
    }

    .padding-container-xs {
        padding: 0;
    }
}
</style>
