<template>
    <svg
        version="1.1"
        class="inis-logo"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 322 112"
        style="enable-background: new 0 0 322 112"
        xml:space="preserve"
    >
        <g id="Symbols">
            <g id="Logo-INIS_Original" transform="translate(0.940000, 0.000000)">
                <g id="Group-22" transform="translate(0.000000, 4.433000)">
                    <polyline
                        id="Fill-21"
                        class="path-red"
                        points="0,0 0,105.4 65.5,105.4 65.5,84.3 23.7,84.3 23.7,0 0,0 			"
                    />
                </g>
                <polygon
                    id="Fill-23"
                    class="path-name"
                    points="94.2,109.8 117,109.8 117,32.8 94.2,32.8 		"
                />
                <path
                    id="Fill-24"
                    class="path-name"
                    d="M181.7,109.8V58.9c0-3.5-1.6-5.5-5.5-5.5c-4.1,0-11.7,2.8-16.6,5.5v50.9h-22.8V32.8h16.9
			    l2.4,5.9c9.5-4.6,21.8-7.4,30.7-7.4c13.1,0,17.7,9.8,17.7,24.7v54H181.7"
                />
                <path
                    id="Fill-25"
                    class="path-name"
                    d="M224.3,24.1h22.8V0h-22.8V24.1z M224.3,24.1h22.8V0h-22.8V24.1z M224.3,109.8h22.8V32.8h-22.8
			    V109.8z"
                />
                <path
                    id="Fill-26"
                    class="path-name"
                    d="M291.7,111.4c-8.4,0-20.4-2.1-28-4.6l3.6-16.9c6.3,1.9,14.7,3.5,23.3,3.5c6.8,0,7.9-1.9,7.9-6.5
			    c0-4.9-0.2-5.5-12.5-8.5c-18.7-4.6-20.4-9.7-20.4-24.1c0-16.3,6.3-23.1,29.4-23.1c5.9,0,16.6,0.8,23.4,2.5l-2.1,17.7
			    c-6.8-1.3-15.2-2.2-21.2-2.2c-6.6,0-8.2,1.7-8.2,5.2c0,4.8,0.6,5.4,9.8,7.4c22,4.9,23.6,9.7,23.6,25
			    C320.3,102.7,313.7,111.4,291.7,111.4"
                />
                <polygon
                    id="Fill-27"
                    class="path-name"
                    points="94.1,24.1 116.9,24.1 116.9,0 94.1,0 		"
                />
                <polygon
                    id="Fill-27-Copy"
                    class="path-name"
                    points="224.1,24.1 246.9,24.1 246.9,0 224.1,0 		"
                />
                <polyline
                    id="Fill-28"
                    class="path-red"
                    points="73.1,0 50.4,0 50.4,24.1 61.8,24.1 54.6,44.7 66.8,44.8 73.1,24.1 73.1,24.1
			    73.1,24.1 73.1,0 		"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'LogoInis',
}
</script>
