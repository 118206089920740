<template>
    <div>
        <aside
             id="alert-box"
             class="alert-box"
             v-if="alertBoxContent"
             :class="[{ 'show': shouldShow && alertBoxContent }, theme]"
             :style="{ backgroundImage: `url(https://inis.qc.ca/static-assets/patterns/Pattern_`  + theme +  `.svg)` }"
             role="dialog"
             aria-modal="true"
             aria-labelledby="alert-title"
             aria-describedby="alert-text"
             data-alert-uid="inis-global"
             aria-hidden
        >
            <button class="close" tabindex="-1" aria-label="Fermer la boîte de message" @click="closeAlert">
                <i class="fa-light fa-xmark-large"></i>
            </button>
            <h2 id="alert-title" class="alert-title">
                {{ alertBoxContent.titre  }}
            </h2>

            <div id="alert-text" class="alert-text text-editor" v-html="alertBoxContent.texte"></div>

            <a
                :href="alertBoxContent.alertLink.link"
                :target="alertBoxContent.alertLink.target ? '_blank' : '_self'"
                class="alert-btn button"
                aria-label=""
            >
                {{ alertBoxContent.alertLink.customText }}
            </a>
            <EscalierInis />
        </aside>
        <div class="alert-box-overlay" :class="{ 'show':shouldShow && alertBoxContent }" @click="closeAlert"></div>
    </div>
</template>

<script>
import EscalierInis from '@/components/svgs/escalierInis'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'AlertModule',

     data() {
        return {
            shouldShow: false,
            theme: 'default',
            alertBoxContent: null
        }
    },

    components: {
        EscalierInis
    },

    watch: {
        pageIsLoaded: {
            async handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    if (this.globals.inisGlobalElement) {

                        if (!this.globals.inisGlobalElement || !this.globals.inisGlobalElement.moduleDalert) {
                            return;
                        }

                        const alertBox = this.globals.inisGlobalElement.moduleDalert;

                        if (alertBox.activerLalerte) {
                            const alertDateEtHeureDeDebut = alertBox.dateEtHeureDeDebut;
                            const alertDateEtHeureDeFin = alertBox.dateEtHeureDeFin;

                            const now = new Date();
                            const alertDateEtHeureDeDebutDate = new Date(alertDateEtHeureDeDebut);
                            const alertDateEtHeureDeFinDate = new Date(alertDateEtHeureDeFin);

                            if (now >= alertDateEtHeureDeDebutDate && now <= alertDateEtHeureDeFinDate) {
                                if (!alertBox.restreindreSelonLaLesPages || alertBox.restreindreSelonLaLesPages && alertBox.restreindreSelonLaLesPages.includes(this.content.id.toString())) {
                                    this.theme = alertBox.theme;
                                    this.alertBoxContent = alertBox;
                                    setTimeout(() => {
                                        if (localStorage.getItem('inis.alertModule') !== this.globals.inisGlobalElement.moduleDalert.theme + ' - ' + this.globals.inisGlobalElement.moduleDalert.titre + ' - ' + this.globals.inisGlobalElement.moduleDalert.texte.trim().substring(0, 50) + '...' + ' - ' + this.globals.inisGlobalElement.moduleDalert.alertLink.link ) {
                                            this.shouldShow = true;
                                        }
                                    }, 7000);
                                }
                            }
                        }
                    }
                }
            },
            immediate: true,
        },
    },

    // On loaded...
    mounted() {
    },

    methods: {
        closeAlert() {
            this.shouldShow = false;

            // LocalStorage
            localStorage.setItem('inis.alertModule', this.globals.inisGlobalElement.moduleDalert.theme + ' - ' + this.globals.inisGlobalElement.moduleDalert.titre + ' - ' + this.globals.inisGlobalElement.moduleDalert.texte.trim().substring(0, 50) + '...' + ' - ' + this.globals.inisGlobalElement.moduleDalert.alertLink.link);
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'sidebarMainStep']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
