<template>
    <div class="row">
        <div class="col-12 col-md-3 col-xxl-4 ps-xl-5 pt-5">
            <nav v-if="globals.footerNavCol01.navigation.mainNav">
                <div class="menu-footer-wrapper">
                    <ul class="nav menu-footer">
                        <li
                            v-for="(value, index) in globals.footerNavCol01.navigation.mainNav"
                            :key="'footer-nav-item-01-' + index"
                            :class="[value.link.type == 'menuTitle' ? 'title-item' : 'nav-item']"
                        >
                            <p v-if="value.link.type == 'menuTitle'" class="bold">
                                {{ value.link.data }}
                            </p>
                            <router-link
                                v-if="value.link.type == 'menuLink'"
                                class="nav-link"
                                :to="{ path: value.link.data.fr }"
                                itemtype="https://www.schema.org/SiteNavigationElement"
                            >
                                {{ value.link.itemTitle }}
                            </router-link>
                            <a
                                v-if="value.link.type == 'ExternLink'"
                                class="nav-link"
                                :href="value.link.data"
                                itemtype="https://www.schema.org/SiteNavigationElement"
                            >
                                {{ value.link.itemTitle }}
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>

        <div class="col-12 col-md-3 col-xxl-4 pt-5">
            <nav v-if="globals.footerNavCol02.navigation.mainNav">
                <div class="menu-footer-wrapper">
                    <ul class="nav menu-footer">
                        <li
                            v-for="(value, index) in globals.footerNavCol02.navigation.mainNav"
                            :key="'footer-nav-item-02-' + index"
                            :class="[value.link.type == 'menuTitle' ? 'title-item' : 'nav-item']"
                        >
                            <p v-if="value.link.type == 'menuTitle'" class="bold">
                                {{ value.link.data }}
                            </p>
                            <router-link
                                v-if="value.link.type == 'menuLink'"
                                class="nav-link"
                                :to="{ path: value.link.data.fr }"
                                itemtype="https://www.schema.org/SiteNavigationElement"
                            >
                                {{ value.link.itemTitle }}
                            </router-link>
                            <a
                                v-if="value.link.type == 'ExternLink'"
                                class="nav-link"
                                :href="value.link.data"
                                itemtype="https://www.schema.org/SiteNavigationElement"
                            >
                                {{ value.link.itemTitle }}
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>

        <div class="col-12 col-md-6 col-xxl-4 pt-5 ps-xl-5 ps-xxl-0">
            <p class="bold">{{ $t('Nos coordonnées') }}</p>
            <p class="bold" v-html="globals.contact.inisAddress"></p>
            <a :href="'tel:' + globals.contact.inisPhone">{{ globals.contact.inisPhone }}</a
            ><br />
            <a
                v-if="globals.contact.inisFormLink !== ''"
                :href="globals.contact.inisFormLink"
                target="_blank"
            >
                {{ $t('Nous joindre') }}
            </a>

            <a
                v-else
                :href="'mailto:' + globals.contact.inisEmail"
            >
                {{ $t('Nous joindre') }}
            </a>

            <div class="pt-4">
                <SocialsIcons />
            </div>
        </div>
    </div>
</template>

<script>
import SocialsIcons from '@/components/Parts/SocialsIcons'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'MenusFooter',

    components: {
        SocialsIcons,
    },

    // On loaded...
    mounted() {},

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
