// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore, doc, getDoc, addDoc, setDoc, deleteDoc, getDocs, query, where, limit, orderBy, collection, documentId } from 'firebase/firestore'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebaseConfig from './config'

// Initialize Firebase
const app = initializeApp(firebaseConfig[process.env.VUE_APP_ENVIRONMENT])
const db = getFirestore(app)
const functions = getFunctions(app, 'northamerica-northeast1')
const addNewMemberToCyberimpact = httpsCallable(functions, 'addNewMemberToCyberimpact')
const handleNewSalesforceFormSubscription = httpsCallable(functions, 'handleNewSalesforceFormSubscription')

// Get a document by ID
async function getDocById(collection = 'Formations', id = 'index') {
    const docRef = doc(db, collection, id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data())
        return docSnap
    }

    // console.log("No such document!", null)
    return null
}

// Get a document by slug
async function getDocBySlug(collectionName = 'Formations', slug = '') {
    if (!slug) {
        return
    }

    // console.log('getDocBySlug', collectionName, slug);

    const q = query(collection(db, collectionName), where('slug', '==', slug))

    const querySnapshot = await getDocs(q)

    const results = []

    querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data())
        results.push({
            id: doc.id,
            ...doc.data(),
        })
    })

    // console.log('getDocBySlug results', results);

    return results
}

// Get featured item
async function getFeaturedItem(item = 'Productions') {
    const q = query(
        collection(db, item),
        where('vedetteWeb', '==', true),
        limit(1),
        orderBy('lastUpdate', 'desc')
    )

    const querySnapshot = await getDocs(q)

    let results = null

    querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data())
        results = doc.data()
    })

    return results
}

// Get all documents from collection
async function getAllDocs(collectionName = 'Formations') {
    const q = collection(db, collectionName)

    const querySnapshot = await getDocs(q)

    const results = []

    querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const docData = doc.data()
        if (!docData.removeFromList) {
            results.push({...docData, id: doc.id})
        }
    })

    return results
}

// Get list of latest docs from collection
async function getLatestDocs(collectionName = 'Formations', limitVal = 8) {
    // Get docs from collection where startDate is greater than today('YYY-MM-DD')
    // get more formation to remove lms == true elements
    const q = query(
        collection(db, collectionName),
        where('startDate', '>=', new Date().toISOString().slice(0, 10)),
        limit(limitVal),
        orderBy('startDate', 'asc')
    )
    // const q = query(collection(db, collectionName), limit(limitVal), orderBy('startDate', 'desc'))

    const querySnapshot = await getDocs(q)

    const results = []

    querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const docData = doc.data()
        if (!docData.removeFromList) {
            results.push(doc.data())
        }
    })

    return results
}

// Get formations from suggestion array
async function getSuggestionsData(suggestion = [], filterInterest = false, ignoreId = false) {
    const results = []

    // Pick every formations from suggestion array
    if (suggestion && suggestion.length) {
        // Check for Creation
        const creations = suggestion.filter(item => item.type === 'Creation')
        const suggestionsId = creations.map(item => item.salesforceId)

        if (creations.length) {
            const q = query(collection(db, 'Formations'), where(documentId(), 'in', suggestionsId))

            const querySnapshot = await getDocs(q)

            querySnapshot.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                results.push(doc.data())
            })
        }

        // Check for Cours
        const cours = suggestion.filter(item => item.type === 'Cours')
        const suggestionsId2 = cours.map(item => item.salesforceId)

        if (cours.length) {
            const q2 = query(collection(db, 'Formations'), where('relatedCours', 'in', suggestionsId2))

            const querySnapshot2 = await getDocs(q2)

            querySnapshot2.forEach(doc2 => {
                // doc.data() is never undefined for query doc snapshots
                results.push(doc2.data())
            })
        }
    } else if (!suggestion.length) {
        const q = query(
            collection(db, 'Formations'),
            where('filterInterest', '==', filterInterest),
            where(documentId(), '!=', ignoreId),
            limit(4 - results.length)
        )
        // const q = query(collection(db, 'Formations'), where('filterInterest', '==', filterInterest))

        const querySnapshot = await getDocs(q)

        querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            const docData = doc.data()
            if (!docData.removeFromList) {
                results.push(doc.data())
            }
        })
    }

    return results
}

// Get formateurs from array
async function getFormateursData(formateurs = []) {
    const results = []

    // Pick every Membres from suggestion array
    if (formateurs && formateurs.length) {
        for (const item of formateurs) {
            const q = query(collection(db, 'Membres'), where(documentId(), '==', item.salesforceId))

            const querySnapshot = await getDocs(q)

            // If there is a member with this id
            if (querySnapshot.size > 0) {
                querySnapshot.forEach(doc => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data())
                    results.push({...doc.data(), original: item})
                })
            } else {
                results.push({...item, original: item})
            }
        }
    }

    return results
}

async function authUserAnonymously() {
    // console.log('authUserAnonymously');
    const auth = await getAuth()
    if (!auth.currentUser) {
        await signInAnonymously(auth)
    }
    return auth
}

// Create a new cart
async function createCart(formation) {
    await authUserAnonymously()

    const cart = {
        selection: [
            {
                formationId: formation.id,
                name: formation.title,
                numberSelected: 1,
                type: formation.formationType
            }
        ],
        dateUpdated: new Date(),
    }

    const res = await addDoc(collection(db, 'Paniers'), cart)

    return res.id
}

// Push a new formation to an existing cart
async function addToCart(cart, formation) {
    await authUserAnonymously()

    const updatedCart = cart
    const index = cart.selection.findIndex(item => item.formationId === formation.id)
    if (index > -1) {
        updatedCart.selection.find(item => item.formationId === formation.id).numberSelected++
    } else {
        updatedCart.selection.push({
            formationId: formation.id,
            name: formation.title,
            numberSelected: 1,
            type: formation.formationType
        })
    }
    updatedCart.dateUpdated = new Date()
    await setDoc(doc(db, "Paniers", cart.id), updatedCart)
    return updatedCart.id
}

// Remove a formation from the cart
async function removeFromCart(cart, formationId) {
    await authUserAnonymously()

    const updatedCart = cart
    updatedCart.selection = updatedCart.selection.filter(item => item.formationId !== formationId)
    updatedCart.dateUpdated = new Date()
    await setDoc(doc(db, "Paniers", cart.id), updatedCart)
    if (updatedCart.selection.length <= 0) {
        // console.log({'updatedCart': updatedCart.selection.length})
        await deleteDoc(doc(db, "Paniers", cart.id))
    }
    return updatedCart.id
}

// Update qty of a formation in the cart
async function updateCart(cart, formationId, qty) {
    await authUserAnonymously()

    // console.log({cart, formationId, qty})
    const updatedCart = cart
    const selection = updatedCart.selection
    const index = selection.findIndex(item => item.formationId === formationId)
    if (index > -1) {
        selection[index].numberSelected = parseInt(qty, 10)
        updatedCart.dateUpdated = new Date()
    }
    await setDoc(doc(db, "Paniers", cart.id), updatedCart)
    return updatedCart.id
}

// Bump cart by simply updating the date
async function bumpCart(cart) {
    if (!cart) {
        return
    }

    await authUserAnonymously()

    const updatedCart = cart
    updatedCart.dateUpdated = new Date()
    await setDoc(doc(db, "Paniers", cart.id), updatedCart)
    return updatedCart.id
}

export { getDocById, getDocBySlug, getAllDocs, getLatestDocs, getFeaturedItem, getSuggestionsData, getFormateursData, createCart, addToCart, removeFromCart, updateCart, bumpCart, addNewMemberToCyberimpact, handleNewSalesforceFormSubscription, authUserAnonymously }
