/**
 * CraftApi plugin
 * Please use in coordination with the Element API plugin
 * https://github.com/craftcms/element-api
 */

import axios from 'axios'
import i18n from '../i18n'
// import Store from '@/store'

// import { getAllDocs } from '@/firebase/init'

const ELEMENT_TYPE = 'pages'
const defaultLang = 'fr'

let urlToken = ''

// Get the preview token from the URL
let m = document.location.href.match(/\bx-craft-live-preview=([^&]+)/)
let token = m ? m[1] : ''

let m2 = document.location.href.match(/\btoken=([^&]+)/)
let token2 = m2 ? m2[1] : ''

if (token) {
    // Then forward that on whenever you are sending an API request
    urlToken = `?x-craft-live-preview=${token}&token=${token2}`
}

class CraftApi {
    constructor() {
        if (!process.env.VUE_APP_SITE_BASE_URL) {
            // eslint-disable-next-line no-console
            console.error('Error: Please set VUE_APP_SITE_BASE_URL in .env')
        }

        this.baseUrl = '/api'
    }

    /**
     *
     * Gets any entry by slug, and defaults to pages
     *
     * @param slug
     * @param type
     *
     * @return object
     *
     */
    async getContentBySlug(slug, type = ELEMENT_TYPE, lang = i18n.locale, item = false, firebaseCollection = false) {
        let response = {
            data: {
                firebaseCollection: [],
            },
        }

        const locale = lang === defaultLang ? '' : '/' + lang

        const itemSlug = item ? '/' + item : ''

        const typeOfContent = type ? type + '/' : ''

        const slugOfContent = slug && slug !== 'pagesDynamiques' ? slug : ''

        const promise1 = new Promise((resolve, reject) => {
            axios.get(
                `${
                    process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
                }/${typeOfContent}${slugOfContent}${itemSlug}.json${urlToken}`
            ).then(res => {
                response = res
                resolve(res)
            } ).catch(err => {
                reject(err)
            } )
        });

        let promise2 = null

        if ( firebaseCollection ) {

            // promise2 = new Promise((resolve, reject) => {
            //     (async () => {
            //         try {
            //         const addToRes = await getAllDocs(
            //             firebaseCollection
            //         )

            //         response.data.firebaseCollection = addToRes

            //         resolve(response)
            //         } catch (err) {
            //             reject(err)
            //         }
            //     })()
            // });

            promise2 = new Promise((resolve) => {
                resolve(true)
            });

        } else {
            promise2 = new Promise((resolve) => {
                resolve(true)
            });
        }

        await Promise.all([promise1, promise2]).then(() => {
            // console.log(values);
            return response
        });

        return response
    }

    /**
     *
     * Gets all the globals
     *
     *
     * @return object
     *
     */
    async getGlobals() {
        let response = []
        const locale = i18n.locale === defaultLang ? '' : '/' + i18n.locale

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl}/mainSiteGlobals.json`)
            .then(res => {
                response['mainNav'] = res.data.mainNav
                response['contact'] = res.data.contact
                response['globalTestimonials'] = res.data.globalTestimonials
                response['footerNavCol01'] = res.data.footerNavCol01
                response['footerNavCol02'] = res.data.footerNavCol02
                response['inisGlobalElement'] = res.data.inisGlobalElement
                response['associationsSyndicats'] = res.data.associationsSyndicats
                response['formationTabsTitleSections'] = res.data.formationTabsTitleSections
                response['categoriesCreation'] = res.data.categoriesCreation
                response['categoriesFormations'] = res.data.categoriesFormations
                response['categoriesJob'] = res.data.categoriesJob
                response['blogType'] = res.data.blogType
                response['categoriesLength'] = res.data.categoriesLength
                response['categoriesFormatType'] = res.data.categoriesFormatType
                response['salesforce'] = res.data.salesforce
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                }
            })

        // await axios
        //     .get(`${process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl}/contact.json`)
        //     .then(res => {
        //         response['contact'] = res.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        //     .get(
        //         `${
        //             process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //         }/globalTestimonials.json`
        //     )
        //     .then(res => {
        //         response['globalTestimonials'] = res.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        //     .get(`${process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl}/footerNavCol01.json`)
        //     .then(res => {
        //         response['footerNavCol01'] = res.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        //     .get(`${process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl}/footerNavCol02.json`)
        //     .then(res => {
        //         response['footerNavCol02'] = res.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

            // await axios
            // .get(`${process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl}/inisGlobalElement.json`)
            // .then(res => {
            //     response['inisGlobalElement'] = res.data
            // })
            // .catch(() => {
            //     response = {
            //         code: 500,
            //         message: 'An error occured',
            //         page: null,
            //     }
            // })

            // await axios
            // .get(`${process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl}/associationsSyndicats.json`)
            // .then(res => {
            //     response['associationsSyndicats'] = res.data
            // })
            // .catch(() => {
            //     response = {
            //         code: 500,
            //         message: 'An error occured',
            //         page: null,
            //     }
            // })

        // await axios
        //     .get(
        //         `${
        //             process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //         }/formationTabsTitleSections.json`
        //     )
        //     .then(res => {
        //         response['formationTabsTitleSections'] = res.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        //     .get(
        //         `${
        //             process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //         }/categories/categoriesCreation.json`
        //     )
        //     .then(res => {
        //         response['categoriesCreation'] = res.data.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        //     .get(
        //         `${
        //             process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //         }/categories/categoriesFormations.json`
        //     )
        //     .then(res => {
        //         response['categoriesFormations'] = res.data.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        //     .get(
        //         `${
        //             process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //         }/categories/categoriesJob.json`
        //     )
        //     .then(res => {
        //         response['categoriesJob'] = res.data.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        // .get(
        //     `${
        //         process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //     }/categories/blogType.json`
        // )
        // .then(res => {
        //     response['blogType'] = res.data.data
        // })
        // .catch(() => {
        //     response = {
        //         code: 500,
        //         message: 'An error occured',
        //         page: null,
        //     }
        // })

        // await axios
        //     .get(
        //         `${
        //             process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //         }/categories/categoriesLength.json`
        //     )
        //     .then(res => {
        //         response['categoriesLength'] = res.data.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        //     .get(
        //         `${
        //             process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //         }/categories/categoriesFormatType.json`
        //     )
        //     .then(res => {
        //         response['categoriesFormatType'] = res.data.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        // await axios
        //     .get(
        //         `${
        //             process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
        //         }/salesforce.json`
        //     )
        //     .then(res => {
        //         response['salesforce'] = res.data
        //     })
        //     .catch(() => {
        //         response = {
        //             code: 500,
        //             message: 'An error occured',
        //             page: null,
        //         }
        //     })

        return response
    }

    /**
     *
     * Gets everything for debug purposes
     * Enabled only in development
     *
     * @return object
     *
     */
    async getAllEntries() {
        let response

        if (process.env.NODE_ENV === 'production') {
            return {
                code: 403,
                message: 'Forbidden',
            }
        }

        const LOCALE = i18n.locale === defaultLang ? '' : '/' + i18n.locale

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + LOCALE + this.baseUrl}/debug.json${urlToken}`)
            .then(res => {
                response = res
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                }
            })

        return response
    }

    async getAssetById(assetId, transform = '') {
        let response = []
        const locale = i18n.locale === defaultLang ? '' : '/' + i18n.locale

        await axios
            .get(
                `${
                    process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl
                }/asset/${assetId}.json${transform}`
            )
            .then(res => {
                response['asset'] = res.data
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    asset: null,
                }
            })

        return response
    }
}

export default {
    install(Vue) {
        Vue.prototype.$craft = new CraftApi()
    },
}
