export const loadPageAssets = async () => {
    const minLoadTime = 2500
    const maxLoadTime = 15000

    const pageAssets = document.querySelectorAll('img')
    const pageMedias = document.querySelectorAll('video')
    const nbAssets = pageAssets.length + pageMedias.length

    // If no assets, we add the class to the body
    if (nbAssets === 0) {
        setTimeout(() => {
            document.querySelector('body').classList.add('all-assets-loaded');
            return true
        }, minLoadTime);
    }

    setTimeout(() => {
        document.querySelector('body').classList.add('all-assets-loaded');
        return true
    }, maxLoadTime);

    // When all images and all videos are loaded
    await Promise.all(Array.from(document.images).map(img => {
        if (img.complete) {
            if (img.naturalHeight !== 0 || img.getAttribute('id').includes('batBeacon')) {
                return Promise.resolve();
            }

            return Promise.reject(img);
        }
        return new Promise((resolve, reject) => {
            img.addEventListener('load', resolve);
            img.addEventListener('error', () => reject(img));
        });
    }))

    // We add the class to the body
    document.querySelector('body').classList.add('all-assets-loaded');

    return true
}
