import { render, staticRenderFns } from "./SidebarMain.vue?vue&type=template&id=3a2c93ef&scoped=true&"
import script from "./SidebarMain.vue?vue&type=script&lang=js&"
export * from "./SidebarMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2c93ef",
  null
  
)

export default component.exports