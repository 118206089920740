<template>

    <div id="inis-spinner">
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube blue level-01"></div>
        <div class="cube"></div>
        <div class="cube blue level-02"></div>
        <div class="cube blue level-04"></div>
        <div class="cube blue level-03"></div>
        <div class="cube blue level-05"></div>
        <div class="cube blue level-06"></div>
    </div>

</template>

<script>
export default {
    name: 'stairInis',
}
</script>
